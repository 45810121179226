import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from 'views/components/Loader'
import { Footer } from 'views/components/Footer'
import { Header } from 'views/components/Header'
import { StartCardSection } from 'views/components/StartCardSection'
import { StartChallenge } from 'views/components/StartChallenge'
import { StartDetail } from 'views/components/StartDetail'
import { StartMulti } from 'views/components/StartMulti'
import { StartShades } from 'views/components/StartShades'
import { StartSteps } from 'views/components/StartSteps'
import { StartTop } from 'views/components/StartTop'
import { StartVideo } from 'views/components/StartVideo'
import { Container } from './styled'
import { NavigationSteps } from 'views/components/NavigationSteps'

export const StartProgram: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state.content?.learnMore ? (
        <Container>
          <Header />
          <StartTop />
          <StartShades />
          <StartChallenge />
          {/*  <StartCardSection /> */}
          <StartDetail />
          <StartMulti />
          <StartVideo />
          <NavigationSteps />
          <StartSteps />
          <Footer />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  )
}

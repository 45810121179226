import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Container, Content, Title, Info, Close, Width } from './styled'

export const StartMulti: FC = () => {
  const state = useSelector(staticSelector)

  const { title, close, paragraph } = state.content?.learnMore.multi

  return (
    <Container>
      <Width>
        {title ? <Title>{title}</Title> : null}
        <Content>
          {paragraph ? <Info dangerouslySetInnerHTML={{ __html: paragraph }} /> : null}
          {close ? <Close>{close}</Close> : null}
        </Content>
      </Width>
    </Container>
  )
}

import { staticSelector } from 'ducks/static/selectors'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoaderMetrics } from '../LoaderMetrics'
import { MetricsTab4SubTab1 } from '../MetricsTab4SubTab1'
import { MetricsTab4SubTab2 } from '../MetricsTab4SubTab2'
import { MetricsTab4SubTab3 } from '../MetricsTab4SubTab3'
import { Community } from '../MetricsPage/types'
import { Container, Content, Line, Tab, TabContainer, TabList, TabPanel, Tabs } from './styled'

interface Props {
  metrics: Metric[]
  community: Community
}

interface Metric {
  id: number
  name: string
  result: string
  savedMoney: string
  equivalentValue: string
}

export const MetricasTab4: FC<Props> = ({ metrics, community }) => {
  const state = useSelector(staticSelector)
  const [activeTab, setActiveTab] = useState(0)

  const index = 0
  const handleTabs = () => {
    if (metrics?.length) {
      if (activeTab === 0) return <MetricsTab4SubTab1 />
      if (activeTab === 1)
        return (
          <MetricsTab4SubTab2
            result={metrics[0]?.result}
            equivalentValue={metrics[0]?.equivalentValue}
            savedMoney={metrics[0]?.savedMoney}
            community={community}
          />
        )
      if (activeTab === 2) return <MetricsTab4SubTab3 community={community} />
    }
  }
  return (
    <Container>
      <Content>
        <Tabs>
          <TabList>
            <TabContainer key={index}>
              <Tab className={`tab ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                Share your data
              </Tab>
              <Tab className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                Community data
              </Tab>
              <Tab className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                Businesses data
              </Tab>
            </TabContainer>
          </TabList>
          <Line />
          {metrics?.length ? <TabPanel>{handleTabs()}</TabPanel> : <LoaderMetrics />}
        </Tabs>
      </Content>
    </Container>
  )
}

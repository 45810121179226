import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const link = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 5px;
  letter-spacing: 0.28px;
  color: #062a20;
  margin: 20px 16px;
  &:hover {
    color: #dfa000;
    text-decoration: underline;
  }
`

export const ExternalLink = styled.a`
  ${link}
`

export const InternalLink = styled(Link)`
  ${link}
`

type MenuButtonProps = {
  close?: boolean
}

export const MenuButton = styled.div<MenuButtonProps>`
  position: absolute;
  margin-left: 10px;
  top: ${({ close }) => close && '22px'};
`

export const MenuButton2 = styled.div<MenuButtonProps>`
  position: absolute;
  top: ${({ close }) => close && '22px'};
  // left: 245px;
`

type MenuContainerProps = {
  open?: boolean
}

export const MenuContainer = styled.div<MenuContainerProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 999;
  // right: 0;
  height: 320px;
  top: 0;
  border-radius: 30px;
  background-color: #faf4e7;
  padding: 48px 16px 16px;
  width: 30%;
  clip-path: circle(${({ open }) => (open ? '141.3% at 0 0' : '0.3% at 0 0')});
  transition: all 0.3s ease-in-out;
  box-shadow: 8px 10px 13px -4px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 600px) {
    width: 35%;
  }

  @media screen and (max-width: 505px) {
    width: 40%;
  }

  @media screen and (max-width: 442px) {
    width: 50%;
  }

  @media screen and (max-width: 354px) {
    width: 70%;
  }
`

export const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
`

export const Login = styled.a`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 5px;
  letter-spacing: 0.28px;
  margin: 20px 16px;
  color: #062a20;
  // margin-left: 38px;
`

export const LoginContainer = styled.div`
  width: 100%;
  background-color: #54ae2a;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  // margin-top: 8px;
`

import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  Content,
  Title,
  Line,
  Info,
  Business,
  DateContainer,
  Tabs,
  Range,
  DateInputContainer,
  Vector,
  TopContainer,
  Calendar,
  TabList,
  Tab,
  TabPanel,
  BusinessContainer,
} from './styled'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { MetricsTab1 } from '../MetricsTab1'
import { RangeDatePicker } from '../DatePicker'
import vector from 'assets/Vector5.png'
import calendar from 'assets/Calendar_icon.png'
import { MetricsTab2 } from '../MetricsTab2'
import { MetricsTab3 } from '../MetricsTab3'
import { metricsSelector, resetMetrics, setCommunity, setMetrics } from 'ducks/metrics'
import { getMetrics, getMetricsByDate, getMetricsCommunity } from 'services/getMetrics'
import { Data } from './types'
import { MetricasTab4 } from '../MetricsTab4'

export const MetricsPage: FC = () => {
  const state = useSelector(staticSelector)
  const metrics: Data = useSelector(metricsSelector)

  const [activeTab, setActiveTab] = useState('tab1')

  const handleTabs = () => {
    if (activeTab === 'tab1') return <MetricsTab1 metrics={metrics?.metrics?.metrics} />
    if (activeTab === 'tab2') return <MetricsTab2 metrics={metrics?.metrics?.metrics} />
    if (activeTab === 'tab3') return <MetricsTab3 />
    // if (activeTab === 'tab4') return <Tab4 />
    if (activeTab === 'tab4') return <MetricasTab4 metrics={metrics?.metrics?.metrics} community={metrics?.community} />
  }

  const { title, tab1, tab2, tab3, tab4 } = state.content?.nationalMetric

  const dispatch = useDispatch()

  useEffect(() => {
    if (metrics?.date?.start) {
      dispatch(resetMetrics())
      getMetricsByDate(metrics?.date?.start, metrics?.date?.end).then((response) => {
        dispatch(setMetrics(response.data))
      })
    } else {
      dispatch(resetMetrics())
      getMetrics().then((response) => {
        dispatch(setMetrics(response.data))
      })
    }
  }, [dispatch, metrics?.date?.start, metrics?.date?.end])

  useEffect(() => {
    getMetricsCommunity().then((response) => {
      dispatch(setCommunity(response.data))
    })
  }, [dispatch])

  const clickReference = () => {
    document.getElementById('date-picker')?.click()
  }

  return (
    <Container>
      <Content>
        {title ? <Title>{title}</Title> : null}
        <TopContainer>
          <TabList>
            <Tab className={`first-tab ${activeTab === 'tab1' ? 'enabled' : ''}`} onClick={() => setActiveTab('tab1')}>
              {tab1}
            </Tab>
            <Line />
            <Tab className={`${activeTab === 'tab2' ? 'enabled' : ''}`} onClick={() => setActiveTab('tab2')}>
              {tab2}
            </Tab>
            <Line />
            <Tab className={`${activeTab === 'tab3' ? 'enabled' : ''}`} onClick={() => setActiveTab('tab3')}>
              {tab3}
            </Tab>
            <Line />
            <Tab className={`last-tab ${activeTab === 'tab4' ? 'enabled' : ''}`} onClick={() => setActiveTab('tab4')}>
              {tab4}
            </Tab>
          </TabList>
          <DateContainer>
            <DateInputContainer onClick={() => clickReference()}>
              <Calendar src={calendar} />
              <Range>Date range</Range>
              <Vector src={vector} />
            </DateInputContainer>
            <RangeDatePicker />
          </DateContainer>
        </TopContainer>
        <Tabs>
          <TabPanel>{handleTabs()}</TabPanel>

          <BusinessContainer>
            {activeTab !== 'tab4' ? (
              <Business>Certified business: {metrics?.metrics?.certifiedBusiness}</Business>
            ) : (
              <Business>Business with shared information: {metrics?.community?.totals?.businesses}</Business>
            )}
          </BusinessContainer>
        </Tabs>
      </Content>
    </Container>
  )
}

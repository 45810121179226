import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  width: 100%;
  background-color: #409658;
  display: flex;
  flex-direction: column;
  // align-items: center;
`
export const Width = styled.div`
  max-width: 1370px;
  margin: auto;
`

export const Content = styled.div`
  column-count: 2;
  text-align: justify;
  width: 80%;
  column-gap: 100px;
  // margin: 60px;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 2rem;

  @media screen and (max-width: 1238px) {
    width: 90%;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1000px) {
    column-count: auto;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
`

export const Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: #fffbf4;
  margin-left: 10%;
  margin-top: 2%;

  @media screen and (max-width: 1238px) {
    margin-left: 5%;
  }

  @media screen and (max-width: 1000px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 745px) {
    margin-top: 2rem;
  }
`

export const Info = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.07px;
  color: #fffbf4;
`

export const Close = styled.div`
  // font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: #fffbf4;
  margin-top: 9%;
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Container, Content, ImageContainer, Image, Info, Title, Description } from './styled'

export const StartChallenge: FC = () => {
  const state = useSelector(staticSelector)

  const { info, title, background } = state.content?.learnMore.challenge

  return (
    <Container>
      <Content>
        <ImageContainer>{background?.url ? <Image src={background?.url} /> : null}</ImageContainer>
        <Info>
          {title ? <Title>{title}</Title> : null}
          {info ? <Description dangerouslySetInnerHTML={{ __html: info }} /> : null}
        </Info>
      </Content>
    </Container>
  )
}

import { setHomeStats } from 'ducks/metrics'
import { setStatic } from 'ducks/static'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { getAll } from 'services/getAll'
import { getMetrics, getMetricsOneYear } from 'services/getMetrics'
import MainRouter from './Main'

const Router: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    getAll().then((response) => {
      if (response) {
        dispatch(setStatic(response.data))
      }
    })
    getMetricsOneYear().then((response) => {
      if (response) {
        dispatch(setHomeStats(response.data))
      }
    })
  })
  return (
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  )
}
export default Router

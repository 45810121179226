import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Content,
  ContentContainer,
  ImageContainer,
  Title,
  Image,
} from './styled'

export const Tracker: FC = () => {
  const state = useSelector(staticSelector)

  const {
    background: { url },
    card2: [
      {
        description,
        title,
        link: [{ label, path }],
      },
    ],
  } = state.content.home.track[0]

  return (
    <Container>
      <Content>
        <ImageContainer>{url ? <Image src={url} /> : null}</ImageContainer>
        <Card>
          <CardHeader>{title ? <Title>{title}</Title> : null}</CardHeader>
          <ContentContainer>{description ? <CardContent>{description}</CardContent> : null}</ContentContainer>
          {path && label ? (
            <Button href={path} target="_blank">
              {label}
            </Button>
          ) : null}
        </Card>
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  // width: 100%;
  background-color: #fffbf4;
  transition: 0.1s all ease-in-out;
`
export const Width = styled.div`
  max-width: 1370px;
  margin: auto;
`

export const MainTitle = styled.h3`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  color: #062a20;
  text-align: center;
`

export const SubContainer = styled.div`
  margin-bottom: 50px;

  // width: 90%;
`

export const Content = styled.div`
  cursor: pointer;

  /* display: flex;
  flex-direction: column; */
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
`

export const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  // text-align: center;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  animation: fadein 1s linear 1 forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const Vector = styled.img`
  width: 20px;
  cursor: pointer;
  // margin: auto;
`

export const SeeMore = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0.07px;
  color: #409658;
  margin-right: 10px;
  cursor: pointer;
`

export const Card = styled.div`
  /* width: 1116px;
  height: 378; */
  width: 80%;
  background-color: #fefcf7;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 25px;
  box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13);

  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 870px) {
    margin-right: 1rem;
  }
`

export const Icon = styled.img`
  height: 45px;
  width: 45px;
`

export const Step = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const Title = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const ContentInfo = styled.div`
  width: 90%;
  margin: auto;
`

export const HiddenTextContainer = styled.div`
  width: 90%;
  margin: auto;
`

export const HiddenText = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 31px;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const Info = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 31px;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const NavigationContainer = styled.div`
  height: 177px;
  background-color: #fefcf7;
  box-shadow: 8px 4px 10px rgba(50, 50, 50, 0.1);
  margin-bottom: 40px;
`

export const NavigationContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  height: 100%;
  margin: auto;
  align-items: center;
`

export const NavIconContainer = styled.div`
  display: flex;
  justify-content: center;

  &:hover {
    color: #dfa000;
  }
`

export const NavIcon = styled.img`
  height: 42px;
  &:hover {
    color: #dfa000;
  }
`

export const NavTitle = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #062a20;
  margin-top: 10px;
  &:hover {
    color: #dfa000;
  }
`

export const NavInfo = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #062a20;
  width: 70%;
  margin: auto;
  margin-top: 10px;
`
export const LinksContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  cursor: pointer;

  &:hover {
    & ${NavIcon} {
      color: #dfa000;
    }
    & ${NavTitle} {
      color: #dfa000;
    }
    & ${NavInfo} {
      color: #dfa000;
    }
  }
`

export const Line = styled.hr`
  border: 1px solid #000;
  width: 50%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
`

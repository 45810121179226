import styled from 'styled-components'

export const Container = styled.div`
  height: 484px;
  // width: 100%;
  background-color: #fffbf4;

  @media screen and (max-width: 1085px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 1085px) {
    flex-direction: column-reverse;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  color: #062a20;
  width: 80%;
  text-align: left;

  @media screen and (max-width: 1153px) {
    // width: 70%;
    // margin-top: 1rem;
  }

  @media screen and (max-width: 1085px) {
    margin-top: 2rem;
    // width: 50%;
  }

  @media screen and (max-width: 790px) {
    // width: 80%;
  }

  @media screen and (max-width: 390px) {
    font-size: 30px;
  }

  @media screen and (max-width: 310px) {
    font-size: 27px;
  }
`

export const Description = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.07px;
  color: #062a20;
  width: 80%;

  @media screen and (max-width: 1153px) {
    // width: 70%;
  }

  @media screen and (max-width: 1085px) {
    // width: 80%;
  }

  @media screen and (max-width: 790px) {
    width: 80%;
  }
`

export const Close = styled.h2`
  font-family: Raleway;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: 0.07px;
  color: #062a20;
  width: 60%;

  @media screen and (max-width: 1153px) {
    width: 70%;
  }

  @media screen and (max-width: 1085px) {
    width: 50%;
  }

  @media screen and (max-width: 790px) {
    width: 80%;
  }
`

export const ImageContainer = styled.div`
  height: 100%;
`

export const Image = styled.img`
  height: 100%;
  width: 673px;
  float: right;
  object-fit: cover;

  @media screen and (max-width: 675px) {
    width: 90vw;
  }
`

export const Button = styled.a`
  display: block;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 20rem;
  height: 42px;
  justify-content: center;
  margin-top: 2rem;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
  ::after {
    content: 'ᐳ';
    margin-left: 40px;
    @media screen and (max-width: 395px) {
      display: none;
    }
  }

  @media screen and (max-width: 1153px) {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 395px) {
    width: 15rem;
  }
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Footer } from 'views/components/Footer'
import { Header } from 'views/components/Header'
import { Loader } from 'views/components/Loader'
import { MetricsPage } from 'views/components/MetricsPage'
import { Container } from './styled'

export const Metrics: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state?.content?.nationalMetric ? (
        <Container>
          <Header />
          <MetricsPage />
          <Footer />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  )
}

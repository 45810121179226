import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // max-width: 1370px;
  // margin: auto;
`

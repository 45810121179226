import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Footer } from 'views/components/Footer'
import { Header } from 'views/components/Header'
import { Loader } from 'views/components/Loader'
import { Container } from './styled'
import { Vulnerability } from 'views/components/Vulnerability'

export const VulnerabilityIndex: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state.content?.nationalState ? (
        <Container>
          <Header />
          <Vulnerability />
          <Footer />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  )
}

import { createSlice } from '@reduxjs/toolkit'
// import { createSagasTrigger } from 'lib/redux/sagas'

export const initialState = {
  metrics: {},
  homeStats: {},
  statsOneYear: {},
  date: {},
  community: {},
}

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setMetrics(state, { payload }) {
      state.metrics = payload
    },
    setDate(state, { payload }) {
      state.date = payload
    },
    resetMetrics(state) {
      state.metrics = {}
    },
    setHomeStats(state, { payload }) {
      state.homeStats = payload
    },
    setOneYear(state, { payload }) {
      state.statsOneYear = payload
    },
    setCommunity(state, { payload }) {
      state.community = payload
    },
  },
})

export const { setMetrics, setDate, resetMetrics, setHomeStats, setOneYear, setCommunity } = metricsSlice.actions

export const metricsReducer = metricsSlice.reducer

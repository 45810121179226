import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Contact } from 'views/components/Contact'
import { FooterHome } from 'views/components/FooterHome'
import { Header } from 'views/components/Header'
import { Loader } from 'views/components/Loader'
import { Container } from './styled'

export const ContactPage: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state.content?.home?.contact ? (
        <Container>
          <Header />
          <Contact />
          <FooterHome />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  )
}

import { staticSelector } from 'ducks/static'
import useMediaQuery from 'hooks/useMediaQuery'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import { Container, Logo, Options, ExternalLink, LogoContainer, LoginContainer, Login, Line, Content } from './styled'

export const Header: FC = () => {
  const matches = useMediaQuery('(min-width: 1080px)')
  const state = useSelector(staticSelector)

  const { label, path } = state.content.header.login[0]

  return (
    <Content>
      <Container>
        {state.content && matches ? (
          <LogoContainer>
            <Link to="/">
              <Logo src={state.content.header.logo.url} />
            </Link>
          </LogoContainer>
        ) : (
          <LogoContainer>
            <Link to="/">
              {state.content.header.logoResponsive ? <Logo src={state.content.header.logoResponsive?.url} /> : null}
            </Link>
          </LogoContainer>
        )}
        {matches ? (
          <>
            <Options>
              {state.content.header?.options?.map((item: any, index: number) => {
                return (
                  <ExternalLink key={`header-links-${index}`} href={item.path}>
                    {item.label}
                  </ExternalLink>
                )
              })}
            </Options>
            <Line />
            <LoginContainer>
              <Login href={path}>{label}</Login>
            </LoginContainer>
          </>
        ) : (
          <Menu />
        )}
      </Container>
    </Content>
  )
}

import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  Content,
  Header,
  TitleContainer,
  Title,
  Icon,
  ItemContainer,
  Item,
  FooterContainer,
  FooterItem1,
  FooterItem2,
  FooterItem3,
  FooterItem4,
  FooterItem5,
  FooterItem6,
  IconContainer,
  HeaderContainer,
  ItemRow,
  InfoContainer,
  FooterRow,
  GasContainer,
  LoaderContainer,
  HeaderContainer2,
  Header2,
  TitleContainer2,
  FooterContainer2,
} from './styled'
import arrows from 'assets/arrows.png'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { LoaderMetrics } from '../LoaderMetrics'

interface Props {
  metrics: Metric[]
}
interface Metric {
  id: number
  name: string
  result: string
  savedMoney: string
  equivalentValue: string
}

export const MetricsTab2: FC<Props> = ({ metrics }) => {
  const state = useSelector(staticSelector)

  const { header } = state.content?.nationalMetric.table

  let infoTable = [
    { gas: 72381372, energy: 199575093, solid: 7730434, water: 219345670, fuel: 43648, waste: 45293 },
    { gas: 65432235, energy: 230575093, solid: 5754362, water: 125546670, fuel: 34257, waste: 36342 },
    { gas: 45432235, energy: 205756093, solid: 8630434, water: 245985754, fuel: 23366, waste: 70348 },
    { gas: 85365568, energy: 175875459, solid: 3458526, water: 225453670, fuel: 36146, waste: 60293 },
  ]

  const [sorted, setSorted] = useState<any>(infoTable)

  const [flag0, setFlag0] = useState<boolean>(true)
  const [flag1, setFlag1] = useState<boolean>(true)
  const [flag2, setFlag2] = useState<boolean>(true)
  const [flag3, setFlag3] = useState<boolean>(true)
  const [flag4, setFlag4] = useState<boolean>(true)
  const [flag5, setFlag5] = useState<boolean>(true)

  // const [order, setOrder] = useState<number>()

  /*   const gas = [72381372, 65432235, 45432235, 85365568]

  const energy = [199575093, 230575093, 205756093, 175875459]

  const solid = [7730434, 5754362, 8630434, 3458526]

  const water = [219345670, 125546670, 245985754, 225453670]

  const fuel = [43648, 34257, 23366, 36146]

  const waste = [45293, 36342, 70348, 60293] */

  const sumGas = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].gas
    }
    return sum
  }

  const sumEnergy = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].energy
    }
    return sum
  }

  const sumSolid = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].solid
    }
    return sum
  }

  const sumWater = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].water
    }
    return sum
  }

  const sumFuel = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].fuel
    }
    return sum
  }

  const sumWaste = () => {
    let sum = 0
    for (let i = 0; i < infoTable.length; i++) {
      sum = sum + infoTable[i].waste
    }
    return sum
  }

  const sortData = (index: number) => {
    if (index === 0) {
      if (flag0) {
        infoTable = infoTable.sort((a, b) => a.gas - b.gas)
        setSorted(infoTable)
        setFlag0(!flag0)
      } else {
        infoTable = infoTable.sort((a, b) => b.gas - a.gas)
        setSorted(infoTable)
        setFlag0(!flag0)
      }
    }
    if (index === 1) {
      if (flag1) {
        infoTable = infoTable.sort((a, b) => a.energy - b.energy)
        setSorted(infoTable)
        setFlag1(!flag1)
      } else {
        infoTable = infoTable.sort((a, b) => b.energy - a.energy)
        setSorted(infoTable)
        setFlag1(!flag1)
      }
    }
    if (index === 2) {
      if (flag2) {
        infoTable = infoTable.sort((a, b) => a.solid - b.solid)
        setSorted(infoTable)
        setFlag2(!flag2)
      } else {
        infoTable = infoTable.sort((a, b) => b.solid - a.solid)
        setSorted(infoTable)
        setFlag2(!flag2)
      }
    }
    if (index === 3) {
      if (flag3) {
        infoTable = infoTable.sort((a, b) => a.water - b.water)
        setSorted(infoTable)
        setFlag3(!flag3)
      } else {
        infoTable = infoTable.sort((a, b) => b.water - a.water)
        setSorted(infoTable)
        setFlag3(!flag3)
      }
    }
    if (index === 4) {
      if (flag4) {
        infoTable = infoTable.sort((a, b) => a.fuel - b.fuel)
        setSorted(infoTable)
        setFlag4(!flag4)
      } else {
        infoTable = infoTable.sort((a, b) => b.fuel - a.fuel)
        setSorted(infoTable)
        setFlag4(!flag4)
      }
    }
    if (index === 5) {
      if (flag5) {
        infoTable = infoTable.sort((a, b) => a.waste - b.waste)
        setSorted(infoTable)
        setFlag5(!flag5)
      } else {
        infoTable = infoTable.sort((a, b) => b.waste - a.waste)
        setSorted(infoTable)
        setFlag5(!flag5)
      }
    }
  }

  return (
    <Container>
      {metrics?.length && header?.length ? (
        <Content>
          <HeaderContainer>
            <Header>
              <TitleContainer>
                <Title>{header[0].title}</Title>
              </TitleContainer>
              <TitleContainer>
                <Title>{header[1].title}</Title>
              </TitleContainer>
              <TitleContainer>
                <Title>{header[2].title}</Title>
              </TitleContainer>

              {/*   {header && header.length
                ? header.map((item: any, index: number) => {
                    return (
                      <TitleContainer key={index}>
                        <Title>{item.title}</Title>
                      </TitleContainer>
                    )
                  })
                : null} */}
            </Header>
          </HeaderContainer>

          <FooterRow>
            <FooterContainer>
              <FooterItem1>{metrics[0]?.result} lbs</FooterItem1>
              <FooterItem2>{metrics[1]?.result} kWh</FooterItem2>
              <FooterItem3>{metrics[2]?.result} lbs</FooterItem3>
            </FooterContainer>
          </FooterRow>

          <HeaderContainer2>
            <Header2>
              <TitleContainer2>
                <Title>{header[3].title}</Title>
              </TitleContainer2>
              <TitleContainer2>
                <Title>{header[4].title}</Title>
              </TitleContainer2>
              <TitleContainer2>
                <Title>{header[5].title}</Title>
              </TitleContainer2>
            </Header2>
          </HeaderContainer2>

          <FooterRow>
            <FooterContainer2>
              <FooterItem4>{metrics[3]?.result} gal</FooterItem4>
              <FooterItem5>{metrics[4]?.result} gal</FooterItem5>
              <FooterItem6>{metrics[5]?.result} gal</FooterItem6>
            </FooterContainer2>
          </FooterRow>
        </Content>
      ) : (
        <LoaderContainer>
          <LoaderMetrics />
        </LoaderContainer>
      )}
    </Container>
  )
}

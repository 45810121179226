import { createGlobalStyle } from 'styled-components'

const StyledApp = createGlobalStyle`
  body {
    // width: 100vw;
    // height: 100vh;
    
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif, 'BegumW03-Bold', 'Raleway', 'Nunito Sans', 'Inter';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
  }
  #root {
    // width: 100vw;
    // height: 320,5vh;
    background-color: #E5E5E5;
    box-sizing: border-box;    
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`
export default StyledApp

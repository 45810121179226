import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Container, Content, Column, Title, Info } from './styled'

export const StartDetail: FC = () => {
  const state = useSelector(staticSelector)

  const { smallColumns } = state.content?.learnMore.details

  return (
    <Container>
      <Content>
        {state.content &&
          smallColumns.map((item: any, index: number) => {
            return (
              <Column key={`columns-detail-${index}`}>
                {item.title ? <Title>{item.title}</Title> : null}
                {item.info ? <Info>{item.info}</Info> : null}
              </Column>
            )
          })}
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  // width: 100%;
  background-color: #dacdb6;
  /* display: flex;
  align-items: center; */
`
export const Width = styled.div`
  display: flex;
  align-items: center;
  max-width: 1370px;
  margin: auto;
`

export const Content = styled.div`
  column-count: 2;
  text-align: justify;
  // width: 90%;
  column-gap: 100px;
  margin: 2rem 4rem 2rem 4rem;

  @media screen and (max-width: 1085px) {
    column-count: auto;
  }

  @media screen and (max-width: 473px) {
    text-align: left;
  }

  @media screen and (max-width: 535px) {
    margin: 2rem 2rem 2rem 2rem;
  }
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  color: #062a20;
  text-align: left;
`

export const SubTitle = styled.div`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: 0.07px;
  color: #062a20;
  margin-bottom: 10px;
`

export const Columns = styled.div``

export const ColumnTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: 0.07px;
  color: #0c1d11;
  text-align: left;
`

export const ColumnText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  text-align: left;
  letter-spacing: 0.07px;
  color: #062a20;
  margin-top: 15px;
`

export const ColumnsContainer = styled.div`
  @media screen and (max-width: 1085px) {
    display: flex;
    flex-direction: column;
  }
`

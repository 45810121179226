import styled from 'styled-components'

export const Container = styled.div`
  min-height: 663px;
  // width: 100%;
  background-color: #fffbf4;
  display: flex;
  justify-content: center;
  // max-width: 1370px;
  // margin: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`

export const WidthMap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 685px;
`

export const WidthInfo = styled.div`
  max-width: 685px;
`

export const MapSection = styled.div`
  display: flex;
  justify-content: flex-end;
  // justify-content: center;
  // width: 100%;
  background-color: #409658;
  flex: 1;

  @media screen and (max-width: 1050px) {
    justify-content: center;
    padding-bottom: 4rem;
    flex: 0;
  }

  @media screen and (max-width: 610px) {
    padding-bottom: 0;
    flex: 0;
  }
`
export const MapContainer = styled.div`
  // width: 500px;
  // margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  // margin-right: 40px;

  @media screen and (max-width: 610px) {
    width: 97vw;
    max-height: 400px;
  }

  @media screen and (max-width: 585px) {
    margin-top: 0;
  }

  @media screen and (max-width: 490px) {
    max-height: 370px;
  }
`

export const TextContainer = styled.div`
  margin-left: 2rem;
  // margin-bottom: 30px;
  margin-top: 3rem;

  @media screen and (max-width: 400px) {
    margin-left: 0.5rem;
  }
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  display: flex;
  align-items: center;
  color: #fffbf4;
`

export const SubTitle = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.07px;
  color: #fffbf4;
  margin-top: 10px;
`

export const InfoSection = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fffbf4;
  // width: 40%;
  flex: 1;

  @media screen and (max-width: 1050px) {
    min-height: 400px;
    flex: 0;
  }
`

export const IntroMessageContainer = styled.div`
  display: flex;
  height: 100%;
  animation: fadein 0.7s linear 1 forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const Diamond = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 25px solid #fffbf4;
  border-bottom: 12px solid transparent;
  margin-top: 175px;
  margin-left: 40px;
  // border-radius: 3.26704px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const IntroMessage = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 42px;
  display: flex;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 340px) {
    font-size: 18px;
  }
`

export const CardStates = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  margin-top: 6rem;
  // justify-content: left;
  animation: fadein 0.5s linear 1 forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1050px) {
    padding-bottom: 4rem;
    margin-top: 3rem;
  }
`

export const IconContainer = styled.div`
  width: 60%;
`

export const Icon = styled.img`
  max-height: 101px;
  max-width: 80%;
`

export const InfoTitle = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #062a20;
`

export const InfoDescription = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #062a20;
  margin-bottom: 30px;
`

export const Button = styled.a`
  display: block;
  color: #062a20;
  background-color: #ffc42d;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #062a20;
  width: 214px;
  height: 42px;
  justify-content: center;

  ::after {
    content: 'ᐳ';
    margin-left: 20px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

import { createSlice } from '@reduxjs/toolkit'
// import { createSagasTrigger } from 'lib/redux/sagas'

export const initialState = {
  content: [],
}

const staticSlice = createSlice({
  name: 'static',
  initialState,
  reducers: {
    setStatic(state, action) {
      state.content = action.payload
    },
  },
})

export const { setStatic } = staticSlice.actions

export const staticReducer = staticSlice.reducer

import styled from 'styled-components'

export const Container = styled.div`
  // height: 100%;
`

export const Content = styled.table`
  width: 65%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`

export const HeaderContainer = styled.thead`
  background-color: #ffc42d;
  border-radius: 20px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderContainer2 = styled(HeaderContainer)`
  margin-top: 50px;
`

export const Header = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  // margin-right: 60px;
  // margin-left: 60px;
`

export const Header2 = styled(Header)`
  width: 100%;
  justify-content: space-between;
`

export const TitleContainer = styled.th`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-right: 20px;
  margin-left: 20px;
`

export const TitleContainer2 = styled(TitleContainer)`
  margin-right: 0px;
  margin-left: 0px;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: medium;
  font-size: 18px;
  // line-height: 95%;
  text-align: center;
  color: #062a20;
  width: 80%;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100%;
`

export const Icon = styled.img`
  height: 10px;
  cursor: pointer;
`

export const ItemRow = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row;
  justify-content: space-around; */
  background-color: #f6eacb;
  opacity: 0.8;
  border-radius: 20px;
  height: 52px;
  margin-top: 10px;
`

export const ItemContainer = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
`

export const InfoContainer = styled.div``

export const GasContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.td`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 91.5%;
  color: #062a20;
  height: 52px;
  display: flex;
  align-items: center;
`

export const FooterRow = styled.tfoot`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fedb81;
  border-radius: 20px;
  height: 70px;
  margin-top: 10px;
`

export const FooterContainer = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // align-items: center;
  width: 90%;
`

export const FooterContainer2 = styled(FooterContainer)`
  justify-content: space-between;
  width: 80%;
`

export const FooterItem1 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  // margin-left: 20px;
`

export const FooterItem2 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  // margin-left: 12px;
`

export const FooterItem3 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  // margin-right: 30px;
`

export const FooterItem4 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  // margin-right: 50px;
`

export const FooterItem5 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  margin-right: 30px;
`

export const FooterItem6 = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
`

export const LoaderContainer = styled.div`
  margin-top: 14rem;
`

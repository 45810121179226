import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  // margin-left: 20px;
  padding: 30px 0 46px;
  color: #f1ede1;
  font-family: Nunito Sans;
  position: relative;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  height: 50%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const TitleCol = styled.div`
  font-family: BegumW03-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #f1ede1;
  margin-bottom: 10px;
`

export const Item = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #f1ede1;
  margin-bottom: 4px;
`

export const Container = styled.footer`
  height: 300px;
  /* display: flex;
  bottom: 0px;
  flex-direction: column; */
  background-color: #062a20;
  width: 100%;

  @media screen and (max-width: 1024px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  bottom: 0px;
  flex-direction: column;
`

const link = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  margin: 5px 0px;
  line-height: 26px;
  color: #f1ede1;
  &:hover {
    text-decoration: underline;
  }
`

export const LinkExternal = styled.a`
  ${link}
`

export const LinkInternal = styled(Link)`
  ${link}
`

export const Logo = styled.img`
  height: 140px;
  width: 110px;
  align-self: center;
  margin-top: 40px;
  @media screen and (max-width: 450px) {
    margin-top: 20px;
    height: 80px;
    width: 60px;
  }
`

export const Options = styled(Row)`
  justify-content: space-evenly;
  width: 60%;
  align-items: center;
  padding: 40px 0;

  @media screen and (max-width: 930px) {
    // flex-direction: column;
  }
`

export const SubContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-evenly;
`

export const SubContainerResponsive = styled.div`
  // height: 200px;
  margin-top: 20px;
  margin-right: 60px;
  margin-bottom: 30px;

  @media screen and (max-width: 370px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 305px) {
    margin-right: 20px;
  }
`

export const SocialIcon = styled.img`
  /*   height: 20px;
  width: 20px; */
  color: #f8f4e9;
`
export const Icons = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 930px) {
    margin-bottom: 200px;
  }
`

export const SocialLink = styled.a`
  display: flex;
  justify-content: left;
  align-items: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #f8f4e9;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(170%);
    transform: translateY(-2px);
  }
`

export const Socials = styled(Row)`
  flex-direction: column;
  justify-content: space-evenly;
  color: #f8f4e9;
  @media screen and (max-width: 450px) {
    position: relative;
    margin-top: 21px;
  }
`

export const Line = styled.div`
  border-top: 1px solid #ffffff;
  margin-left: 8%;
  margin-right: 8%;
`

export const SocialContainer = styled.div`
  margin-left: 7%;
  margin-top: 30px;
  margin-bottom: 20px;
`

export const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
`

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 8%;
`

export const CopyrightContainer = styled.div`
  margin-left: 8%;
  margin-bottom: 40px;
`

export const Copyright = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.379167px;
  color: #ffffff;
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Author,
  Button,
  Container,
  Content,
  Description,
  Each,
  Icon,
  Items,
  Line,
  MainInfo,
  Quote,
  Title,
  Width,
} from './styled'

export const Reasons: FC = () => {
  const state = useSelector(staticSelector)

  const {
    author,
    quote,
    title,
    items,
    link: [{ label, path }],
  } = state.content?.home?.reasons

  return (
    <Container>
      <Width>
        <Content>
          <MainInfo>
            {title ? <Title>{title}</Title> : null}
            {quote ? <Quote>{quote}</Quote> : null}
            {author ? <Author>{author}</Author> : null}
            {path && label ? <Button href={path}>{label}</Button> : null}
          </MainInfo>
          <Line />
          <Items>
            {state.content?.home?.reasons &&
              items.map((item: any) => {
                return (
                  <Each key={item.icon.url}>
                    {item.icon.url ? <Icon src={item.icon.url} /> : null}
                    {item.description ? <Description>{item.description}</Description> : null}
                  </Each>
                )
              })}
          </Items>
        </Content>
      </Width>
    </Container>
  )
}

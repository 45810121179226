import { staticSelector } from 'ducks/static'
import { FC, useState } from 'react'
import { FiX, FiMenu } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { ExternalLink, MenuButton, Login, MenuContainer, MenuOptions, LoginContainer, MenuButton2 } from './styled'

const Menu: FC = () => {
  const state = useSelector(staticSelector)

  const { options } = state.content?.header
  const { label, path } = state.content?.header.login[0]

  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const closeMenu = () => {
    setOpen(false)
  }

  const toggleMenu = () => {
    setOpen((prevState) => !prevState)
  }

  const renderOption = (option: any) => {
    return (
      <ExternalLink key={option.path} href={option.path} onClick={closeMenu}>
        {option.label}
      </ExternalLink>
    )
  }

  return (
    <>
      <MenuButton onClick={toggleMenu}>
        <FiMenu color={theme.colors.black} size={24} />
      </MenuButton>
      <MenuContainer open={open}>
        <MenuButton2 close onClick={toggleMenu}>
          <FiX color={theme.colors.black} size={24} />
        </MenuButton2>
        <MenuOptions data-testid="options">{options?.map(renderOption)}</MenuOptions>
        <LoginContainer>
          <Login href={path}>{label}</Login>
        </LoginContainer>
      </MenuContainer>
    </>
  )
}

export default Menu

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  Title,
  Card,
  Left,
  LeftIconContainer,
  Icon,
  TextContainer,
  Info,
  Vector,
  Right,
  RightIconContainer,
  LeftTextContainer,
  InfoRed,
  InfoGreen,
} from './styled'
import vector from 'assets/Vector6.png'

interface Props {
  result: string
  equivalentValue: string
  savedMoney: string
}

export const MetricsSubTab5: FC<Props> = ({ result, equivalentValue, savedMoney }) => {
  const state = useSelector(staticSelector)

  const { title, iconLeft, iconRight, textLeft, textRight } = state.content?.nationalMetric.subTabRender[4]

  return (
    <Container>
      <Content>
        {title ? <Title>{title}</Title> : null}
        <Card>
          <Left>
            <LeftIconContainer>{iconLeft.url ? <Icon src={iconLeft.url} /> : null}</LeftIconContainer>
            <LeftTextContainer>
              {result && savedMoney ? (
                <Info>
                  <InfoRed>{result} therms</InfoRed> of natural gas with a total savings of{' '}
                  <InfoRed>${savedMoney}.</InfoRed>
                </Info>
              ) : null}
            </LeftTextContainer>
          </Left>
          <Vector src={vector} />
          <Right>
            <RightIconContainer>{iconRight.url ? <Icon src={iconRight.url} /> : null}</RightIconContainer>
            <TextContainer>
              {equivalentValue ? (
                <Info>
                  Thats the equivalent of <InfoGreen>heating {equivalentValue}</InfoGreen> homes.
                </Info>
              ) : null}
            </TextContainer>
          </Right>
        </Card>
      </Content>
    </Container>
  )
}

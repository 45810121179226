import { staticSelector } from 'ducks/static'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  MapSection,
  InfoSection,
  InfoTitle,
  InfoDescription,
  Button,
  CardStates,
  MapContainer,
  Logo,
  LogoContainer,
  LinkToFile,
} from './styled'

export const Vulnerability: FC = () => {
  const state = useSelector(staticSelector)

  const { title, description, mapEmbedded, buttonText, buttonLink, linkText, pdf } = state.content.nationalVulnerability

  const [loading, setLoading] = useState<boolean>(true)

  return (
    <Container>
      <Content>
        <MapSection>
          <MapContainer>
            {loading && (
              <LogoContainer>
                <Logo />
              </LogoContainer>
            )}
            <iframe
              width="100%"
              height="100%"
              title="Business Vulnerability Index"
              src={mapEmbedded}
              onLoad={() => setLoading(false)}
            ></iframe>
          </MapContainer>
        </MapSection>
        <InfoSection>
          <CardStates>
            {title ? <InfoTitle>{title}</InfoTitle> : null}
            {description ? (
              <InfoDescription style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {linkText ? (
              <InfoDescription>
                <LinkToFile href={pdf.url} target="blank">
                  {linkText}
                </LinkToFile>
              </InfoDescription>
            ) : null}
            {buttonLink ? (
              <Button href={buttonLink} target="_blank">
                {buttonText}
              </Button>
            ) : null}
          </CardStates>
        </InfoSection>
      </Content>
    </Container>
  )
}

import { staticSelector } from 'ducks/static'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Content, ImageContainer, Image, Info, Title, Extended, Button, MapContainer } from './styled'
import USAMap from 'react-usa-map'
import './mapImage.css'
import { metricsSelector } from 'ducks/metrics'

export const Description: FC = () => {
  const state = useSelector(staticSelector)
  // const { homeStats } = useSelector(metricsSelector)

  const {
    background: { url },
    title,
    history,
    link: [{ label, path }],
  } = state.content?.home?.description[0]

  const { stateInfo } = state.content.nationalState
  const [show, setShow] = useState<any>()

  const statesCustomConfig = () => {
    const obj: any = {}
    stateInfo.forEach((e: any) => {
      const { code } = e
      /*  const element: any = document.querySelector(`path.${code}`)
      if (element) {
        element.setAttribute('data-interactive', 'on')
      } */
      obj[code] = {
        fill: '#51A569',
      }
    })
    return obj
  }

  return (
    <Container>
      <Content>
        <ImageContainer>{url ? <Image src={url} /> : null}</ImageContainer>
        {/* <MapContainer className="States">
          <USAMap defaultFill="#DACDB6" customize={statesCustomConfig()} width={600} height={400} />
        </MapContainer> */}
        <Info>
          {title ? (
            <Title>
              {stateInfo.length} {title}
            </Title>
          ) : null}
          {history ? <Extended>{history}</Extended> : null}
          {path && label ? <Button href={path}>{label}</Button> : null}
        </Info>
      </Content>
    </Container>
  )
}

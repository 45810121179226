import React, { FC, useState } from 'react'
import {
  Container,
  IconContainer,
  Icon,
  Title,
  Step,
  Card,
  Info,
  ContentInfo,
  Header,
  TitleContainer,
  Content,
  HiddenText,
  HiddenTextContainer,
  SubContainer,
  SeeMoreContainer,
  SeeMore,
  Vector,
  /*  MainTitle,
  NavigationContainer,
  NavigationContent,
  NavIcon,
  NavTitle,
  NavInfo,
  Line,
  LinksContent,
  NavIconContainer, */
  Width,
} from './styled'
import Collapsible from 'react-collapsible'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
// import { Link } from 'react-scroll'
import vector from 'assets/Vector3.png'
import vectorDown from 'assets/Vector4.png'

export const StartSteps: FC = () => {
  const state = useSelector(staticSelector)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { Steps_cards } = state.content?.learnMore.steps

  // const [open, setOpen] = useState(false)
  const [openId, setOpenId] = useState<number[]>([])

  const clickReference = (index: string) => {
    document.getElementById(index)?.click()
  }

  const handleOpen = (i: number) => {
    // setOpen(!open)
    if (!openId.includes(i)) {
      setOpenId([...openId, i])
    } else {
      setOpenId(openId.filter((e: any) => e !== i))
    }
  }

  return (
    <Container>
      <Width>
        <SubContainer>
          {state.content &&
            Steps_cards.map((item: any, index: number) => {
              const renderTitle = () => {
                return (
                  <Content key={`steps-cards-top-${index}`} id={index.toString()} onClick={() => handleOpen(index)}>
                    <Header>
                      <IconContainer>{item.icon.url ? <Icon src={item.icon.url} /> : null}</IconContainer>
                      <TitleContainer>
                        {item.stepNumber ? <Step>{item.stepNumber}</Step> : null}
                        {item.tile ? <Title>{item.title}</Title> : null}
                      </TitleContainer>
                    </Header>
                    <ContentInfo>
                      {item.description ? <Info dangerouslySetInnerHTML={{ __html: item.description }} /> : null}
                    </ContentInfo>
                    {openId.includes(index) ? null : (
                      <SeeMoreContainer>
                        <SeeMore>See more</SeeMore>
                        <Vector src={vector} />
                      </SeeMoreContainer>
                    )}
                  </Content>
                )
              }
              return (
                <Card>
                  <Collapsible
                    easing="ease-in"
                    transitionTime={400}
                    triggerStyle={{ display: 'flex' }}
                    trigger={renderTitle()}
                    // open={open}
                    /*  onOpening={() => handleOpen()}
                  onClose={() => handleOpen()} */
                  >
                    <HiddenTextContainer>
                      {item.hiddenText ? <HiddenText dangerouslySetInnerHTML={{ __html: item.hiddenText }} /> : null}
                    </HiddenTextContainer>
                    <SeeMoreContainer onClick={() => clickReference(index.toString())}>
                      {' '}
                      <SeeMore>See less</SeeMore>
                      <Vector src={vectorDown} />
                    </SeeMoreContainer>
                  </Collapsible>
                </Card>
              )
            })}
        </SubContainer>
      </Width>
    </Container>
  )
}

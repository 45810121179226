import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  width: 100%;
  background-color: #dacdb6;
  @media screen and (max-width: 1340px) {
    height: auto;
  }
`

export const Width = styled.div`
  max-width: 1370px;
  margin: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 60px;
  margin-right: 40px;
  // margin-bottom: 50px;

  @media screen and (max-width: 1230px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const Line = styled.span`
  border-left: 1px solid #000000;
  height: 152px;
  align-self: center;
  margin-left: 2rem;

  @media screen and (max-width: 1230px) {
    border-top: 1px solid #000000;
    width: 90%;
    height: 0px;
    // margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0;
  }
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1230px) {
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`

export const Title = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 38px;
  font-style: normal;
  line-height: 39px;
  color: #062a20;
  text-align: left;

  @media screen and (max-width: 1230px) {
    font-size: 28px;
    line-height: 29px;
  }
`

export const Quote = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 20.89px;
  color: #062a20;
  text-align: left;

  @media screen and (max-width: 1230px) {
    line-height: 21px;
  }
`

export const Author = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 21.05px;
  color: #062a20;

  @media screen and (max-width: 1230px) {
    display: none;
  }
`

export const Items = styled.div`
  display: flex;
  margin-left: 40px;
  // position: relative;
  z-index: 5;

  @media screen and (max-width: 1230px) {
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 40px;
  }
`

export const Each = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 10px;
`

export const Icon = styled.img`
  height: 30px;
  width: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1230px) {
    margin-top: 20px;
  }
`

export const Description = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16.06px;
  font-style: normal;
  line-height: 19.97px;
  text-align: center;
  color: #062a20;

  @media screen and (max-width: 1230px) {
    width: 50%;
    margin: auto;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 510px) {
    width: 90%;
    margin: auto;
  }
`

export const Button = styled.a`
  // display: block;
  position: relative;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  float: right;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-right: 100px;

  z-index: 8;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }

  ::after {
    content: 'ᐳ';
    margin-left: 40px;
  }

  @media screen and (max-width: 1230px) {
    // margin-right: 36%;
    margin-left: 10%;
  }

  /*  @media screen and (max-width: 830px) {
    margin-right: 35%;
  }

  @media screen and (max-width: 690px) {
    margin-right: 30%;
  } */

  @media screen and (max-width: 570px) {
    // margin-right: 25%;
    margin-left: 15%;
  }

  @media screen and (max-width: 460px) {
    margin-right: 20%;
  }
`

import { FC } from 'react'
import { Header } from 'views/components/Header'
import { Contact } from 'views/components/Contact'
import { Description } from 'views/components/Description'
import { FooterHome } from 'views/components/FooterHome'
import { Intro } from 'views/components/Intro'
import { Metrics } from 'views/components/Metrics'
import { Reasons } from 'views/components/Reasons'
import { Tracker } from 'views/components/Tracker'
import { Container } from './styled'
import { useSelector } from 'react-redux'
import { staticSelector } from 'ducks/static'
import { Loader } from 'views/components/Loader'

const Home: FC = () => {
  const state = useSelector(staticSelector)

  return (
    <>
      {state.content?.home ? (
        <Container>
          <Header />
          <Intro />
          <Reasons />
          <Description />
          <Metrics />
          <Tracker />
          <Contact />
          <FooterHome />
        </Container>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default Home

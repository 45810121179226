import styled from 'styled-components'
import LoadingSVG from 'assets/loading.svg'

export const Container = styled.div`
  height: auto;
  width: 100%;
  background-color: #062a20;
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 860px) {
    // flex-direction: column-reverse;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  // align-items: left;
  // margin-left: 100px;
  // padding-bottom: 100px;

  @media screen and (max-width: 1024px) {
    // width: 60%;
  }

  @media screen and (max-width: 850px) {
    // width: 70%;
  }

  @media screen and (max-width: 620px) {
    //width: 80%;
    // margin-left: 40px;
    // justify-content: center;
  }
`

export const Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  color: #ffffff;
  // align-self: left;

  @media screen and (max-width: 620px) {
    font-size: 28px;
  }
`

export const InputName = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.07px;
  color: #ffffff;
  margin-bottom: 16px;
`

export const IntputEmail = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.07px;
  color: #ffffff;
  margin-bottom: 16px;
  margin-top: 22px;
`

export const InputMessage = styled.label`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.07px;
  color: #ffffff;
  margin-bottom: 16px;
  // margin-top: 22px;
`

export const Input = styled.input`
  background-color: #f8f4e9;
  border-radius: 50px;
  padding-left: 10px;
  width: 80%;
  height: 50px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 19px;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const Message = styled.textarea`
  background-color: #f8f4e9;
  border-radius: 35px;
  min-height: 189px;
  min-width: 90%;
  max-height: 189px;
  max-width: 90%;
  // padding: 10px;
  padding-left: 10px;
  padding-top: 20px;
  // width: 90%;
  // height: 189px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 19px;
  letter-spacing: 0.07px;
  color: #062a20;
`

type ButtonProps = {
  disable?: boolean
}
export const Button = styled.button<ButtonProps>`
  // display: block;
  background-color: #54ae2a;
  border-radius: 20px;
  border-style: none;
  // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-left: 7%;
  margin-top: 35px;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
  ::after {
    content: 'ᐳ';
    padding-left: 15%;
  }

  @media (max-width: 1170px) {
    margin-left: 0px;
  }

  @media (max-width: 530px) {
    margin-top: 10px;
  }
`

export const BlurredButton = styled.button`
  // display: block;
  background-color: grey;
  border-radius: 20px;
  border-style: none;
  // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  // align-self: flex-end;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-left: 7%;
  margin-top: 35px;
  cursor: not-allowed;

  ::after {
    content: 'ᐳ';
    padding-left: 15%;
  }

  @media (max-width: 1170px) {
    margin-left: 0px;
  }

  @media (max-width: 530px) {
    margin-top: 10px;
  }
`

export const ButtonLoading = styled.button`
  // display: block;
  background-color: grey;
  border-radius: 20px;
  border-style: none;
  // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-left: 7%;
  margin-top: 35px;

  @media (max-width: 1170px) {
    margin-left: 0px;
  }

  @media (max-width: 530px) {
    margin-top: 10px;
  }
`
export const LoadingSpinner = styled.img`
  height: 100%;
  width: 20px;
`

LoadingSpinner.defaultProps = {
  alt: 'Loading Animation',
  src: LoadingSVG,
}

export const Line = styled.div`
  border-bottom: 1px solid #ffffff;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 10px;
`

export const Error = styled.p`
  color: #ff4545;
  font-family: Raleway;
  font-size: 18px;
  // line-height: 26px;
  margin: 4px 25px 0;
`

export const ErrorCaptcha = styled.div`
  color: #ff4545;
  font-family: Raleway;
  font-size: 18px;
  // margin: 4px 25px 0;
  margin-left: 5%;
  margin-top: 5px;
`

export const CaptchaContainer = styled.div`
  // margin-left: 40%;
  // margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 17px;
  // display: flex;
  transform: scale(0.97);
  -webkit-transform: scale(0.97);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;

  @media (max-width: 1170px) {
    margin-left: 0px;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  @media (max-width: 1120px) {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  @media (max-width: 950px) {
    transform: scale(0.85);
    transform-origin: 0 0;
  }

  @media (max-width: 700px) {
    /* transform: scale(0.8);
    transform-origin: 0 0; */
    // margin-left: 35%;
  }

  @media (max-width: 575px) {
    transform: scale(0.75);
    transform-origin: 0 0;
  }

  @media (max-width: 488px) {
    transform: scale(0.75);
    transform-origin: 0 0;
    // margin-left: 25%;
  }

  @media (max-width: 430px) {
    transform: scale(0.75);
    transform-origin: 0 0;
    // margin-left: 17%;
  }

  @media (max-width: 390px) {
    transform: scale(0.7);
    transform-origin: 0 0;
    // margin-left: 07%;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  // height: 50%;
  margin: auto;
  display: flex;
  justify-content: left;
  // align-items: center;
  // padding: 20px;
  margin-bottom: 50px;

  @media (max-width: 1385px) {
    margin-right: 20px;
  }

  @media (max-width: 860px) {
    // width: 80%;
    // margin-top: 30px;
  }
`

export const Text = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: medium;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  @media (max-width: 1035px) {
    flex-direction: column;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1035px) {
    width: 80%;
  }

  @media (max-width: 730px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    width: 95%;
  }
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1035px) {
    width: 72%;
    margin-top: 20px;
  }

  @media (max-width: 730px) {
    width: 80%;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 530px) {
    flex-direction: column;
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  height: 482px;
  // width: 100%;
  background-color: #062a20;

  @media screen and (max-width: 1385px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1085px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ImageContainer = styled.div`
  height: auto;

  @media screen and (max-width: 1385px) {
    display: flex;
    align-items: center;
  }
`

export const Image = styled.img`
  width: 728px;

  @media screen and (max-width: 1385px) {
    width: 40vw;
  }

  @media screen and (max-width: 1085px) {
    width: 50vw;
  }

  @media screen and (max-width: 840px) {
    width: 80vw;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-top: 2rem;

  @media screen and (max-width: 1385px) {
    width: 50%;
  }

  @media screen and (max-width: 840px) {
    width: 60%;
  }

  @media screen and (max-width: 750px) {
    width: 80%;
    margin-right: 2rem;
  }

  @media screen and (max-width: 490px) {
    width: 90%;
    margin-right: 2rem;
  }
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: #fffbf4;
  align-self: flex-start;
  text-align: left;
`

export const Description = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  letter-spacing: -0.93px;
  color: #fffbf4;
`

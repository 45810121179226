import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  // border-radius: 0px 0px 20px 20px;
`

export const Content = styled.div``

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Icon = styled.img`
  font-family: Montserrat;
  height: 19px;
  cursor: pointer;
`

export const Tab = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 91.5%;
  color: #062a20;
  width: 100%;
  padding-left: 6px;
  margin-right: 50px;
  cursor: pointer;
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabList = styled.ul`
  display: flex;
  flex-direction: row;
  // align-items: center;
  width: 100%;
  // height: 46px;
  list-style-type: none;
  // background-color: #062a20;
  margin-bottom: 2px;
  box-sizing: border-box;
`

export const TabPanel = styled.div`
  height: 300px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  text-align: center;
  color: #062a20;
  padding-top: 40px;
`

export const Line = styled.div`
  border-top: 1px solid #aa9487;
  margin-left: 30px;
  margin-right: 30px;
`

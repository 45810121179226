import { staticSelector } from 'ducks/static'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { submitContact } from 'services/submitContact'
import {
  Button,
  Container,
  Form,
  Input,
  InputMessage,
  InputName,
  IntputEmail,
  Message,
  Title,
  Error,
  BlurredButton,
  ButtonLoading,
  LoadingSpinner,
  Content,
  ErrorCaptcha,
  CaptchaContainer,
  TextContainer,
  Text,
  FieldsContainer,
  InputsContainer,
  MessageContainer,
  SubmitContainer,
} from './styled'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import './captcha.css'

export type ContactValues = {
  email: string
  name: string
  organization: string
  message: string
}

export type ErrorValues = {
  email?: string
  name?: string
  organization?: string
  message?: string
}

export const Contact: FC = () => {
  const state = useSelector(staticSelector)

  const captcha = useRef(null)

  const { handleSubmit, formState } = useForm()
  const { isSubmitting } = formState

  const [errors, setErrors] = useState<ErrorValues>({})
  const [disable, setDisable] = useState(true)
  const [flag, setFlag] = useState({ name: false, email: false, organization: false, message: false })
  const [captchaToken, setCaptchaToken] = useState(null)
  const [errorCaptcha, setErrorCaptcha] = useState(false)

  const element: any = document.querySelector(`rc-anchor`)
  if (element) {
    element.setAttribute('custom-captcha', 'on')
  }

  useEffect(() => {
    if (Object.values(errors).length === 0) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [errors])

  useEffect(() => {
    setDisable(true)
  }, [])

  const [form, setForm] = useState({
    name: '',
    email: '',
    organization: '',
    message: '',
  })

  const {
    title,
    formName,
    formEmail,
    formMessage,
    formOrganization,
    textField,

    link: [{ label }],
  } = state.content.home.contact[0]

  function validate(input: ErrorValues): ErrorValues {
    const alerts: ErrorValues = {}
    if (!input.name) alerts.name = 'Please enter your name'
    if (!input.email) alerts.email = 'Please enter your email'
    if (!input.organization) alerts.organization = 'Please enter your organization'
    if (!/\S+@\S+\.\S+/.test(input.email ? input.email : '')) alerts.email = 'Please enter a valid email'
    if (!input.message) alerts.message = 'Please write your message'
    return alerts
  }
  function handleChange(e: any) {
    setErrors(
      validate({
        ...form,
        [e.target.name]: e.target.value,
      })
    )
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    if (Object.values(errors).length === 0) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const onSubmit = () => {
    if (captchaToken) {
      setDisable(true)
      setErrorCaptcha(false)
      try {
        const promise = submitContact(form)
        return promise
      } finally {
        setForm({ name: '', email: '', organization: '', message: '' })
      }
    } else {
      setErrorCaptcha(true)
    }
  }

  const captchaChange = (token: any) => {
    setCaptchaToken(token)
    setErrorCaptcha(false)
  }

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {title ? <Title>{title}</Title> : null}
          {textField && textField.length > 1 ? (
            <TextContainer>
              <Text>{textField}</Text>
            </TextContainer>
          ) : null}
          <FieldsContainer>
            <InputsContainer>
              {formName ? <InputName>{formName}</InputName> : null}
              <Input
                name="name"
                value={form.name}
                onChange={(e) => {
                  handleChange(e)
                  setFlag({ ...flag, name: true })
                }}
              />
              {errors.name && flag.name && <Error>{errors.name}</Error>}
              {formEmail ? <IntputEmail>{formEmail}</IntputEmail> : null}
              <Input
                name="email"
                type="email"
                value={form.email}
                onChange={(e) => {
                  handleChange(e)
                  setFlag({ ...flag, email: true })
                }}
              />
              {errors.email && flag.email && <Error>{errors.email}</Error>}
              {formOrganization ? <IntputEmail>{formOrganization}</IntputEmail> : null}
              <Input
                name="organization"
                value={form.organization}
                onChange={(e) => {
                  handleChange(e)
                  setFlag({ ...flag, organization: true })
                }}
              />
              {errors.organization && flag.organization && <Error>{errors.organization}</Error>}
            </InputsContainer>
            <MessageContainer>
              {formMessage ? <InputMessage>{formMessage}</InputMessage> : null}
              <Message
                maxLength={700}
                name="message"
                value={form.message}
                onChange={(e) => {
                  handleChange(e)
                  setFlag({ ...flag, message: true })
                }}
              />
              {errors.message && flag.message && <Error>{errors.message}</Error>}

              <SubmitContainer>
                <CaptchaContainer>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                    onChange={captchaChange}
                    theme="dark"
                  />
                  {errorCaptcha && <ErrorCaptcha>Please click on the captcha</ErrorCaptcha>}
                </CaptchaContainer>
                {isSubmitting ? (
                  <ButtonLoading disabled={true}>
                    {' '}
                    <LoadingSpinner />
                  </ButtonLoading>
                ) : disable ? (
                  <BlurredButton disabled={true} type="submit">
                    {label}
                  </BlurredButton>
                ) : (
                  <Button type="submit">{label}</Button>
                )}
              </SubmitContainer>
            </MessageContainer>
          </FieldsContainer>
        </Form>
      </Content>
    </Container>
  )
}

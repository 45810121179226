import styled, { keyframes } from 'styled-components'
import LogoPNG from 'assets/loaderGreenBiz.png'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`

const glowing = keyframes`
  0% { opacity: 0.7; transform: scale(0.9) }
  50% { opacity: 1; transform: scale(1);  }
  100% { opacity: 0.7; transform: scale(0.9) }
`

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  animation: ${glowing} 1.5s ease-in-out infinite forwards;
`

Logo.defaultProps = {
  src: LogoPNG,
}

import { FC } from 'react'
import { store, persistor } from 'lib/redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { theme } from 'lib/theme'
import StyledApp from 'lib/theme/StyledApp'
import { PersistGate } from 'redux-persist/integration/react'
import Router from './Router'

const App: FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <StyledApp />
        <Router />
      </ThemeProvider>
    </PersistGate>
  </Provider>
)
export default App

import { metricsSelector } from 'ducks/metrics'
import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  Button,
  Container,
  Content,
  Extended,
  Info,
  Title,
  Card,
  Left,
  Right,
  Icon,
  Stats,
  Vector,
  StatsOrg,
  StatsBold,
  StatsGreen,
  CarouselContainer,
  Stats4,
  Stats4b,
} from './styled'

export const Metrics: FC = () => {
  const state = useSelector(staticSelector)
  const { homeStats } = useSelector(metricsSelector)

  const {
    title,
    description,
    link: [{ label, path }],
  } = state.content?.home?.metrics[0]

  const { infoCard } = state?.content?.learnMore?.saved

  return (
    <Container>
      <Content>
        <Info>
          {title ? <Title>{title}</Title> : null}
          {description ? <Extended>{description}</Extended> : null}
          {path && label ? <Button href={path}>{label}</Button> : null}
        </Info>
        {/* <ImageContainer>{url ? <Image src={url} /> : null}</ImageContainer> */}
        <CarouselContainer>
          {infoCard && infoCard.length && homeStats && homeStats?.metrics?.length ? (
            <Carousel
              autoPlay
              infiniteLoop
              interval={3000}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              stopOnHover={false}
              emulateTouch
              centerMode
              showThumbs={false}
              autoFocus
              centerSlidePercentage={100}
            >
              <Card>
                <Left>
                  {infoCard && infoCard.length > 1 && infoCard[0]?.icon?.url ? (
                    <Icon src={infoCard && infoCard.length && infoCard[0]?.icon?.url} />
                  ) : null}
                  {homeStats && homeStats?.metrics[0]?.result && homeStats?.metrics[0]?.savedMoney ? (
                    <Stats>
                      <StatsOrg>{homeStats?.metrics[0]?.result} lbs of CO2</StatsOrg> with a total savings of{' '}
                      <StatsBold>${homeStats?.metrics[0]?.savedMoney}.</StatsBold>
                    </Stats>
                  ) : null}
                </Left>
                {infoCard && Array.isArray(infoCard) ? <Vector src={infoCard[0]?.vector?.url} /> : null}
                <Right>
                  {infoCard && Array.isArray(infoCard) && infoCard[0].iconRight.url ? (
                    <Icon src={infoCard[0]?.iconRight?.url} />
                  ) : null}
                  {homeStats?.metrics[0]?.equivalentValue ? (
                    <Stats>
                      That’s the equivalent of{' '}
                      <StatsGreen>planting {homeStats?.metrics[0]?.equivalentValue} acres</StatsGreen>
                      of urban trees.
                    </Stats>
                  ) : null}
                </Right>
              </Card>

              <Card>
                <Left>
                  {infoCard && Array.isArray(infoCard) && infoCard[1]?.icon?.url ? (
                    <Icon src={infoCard[1]?.icon?.url} />
                  ) : null}
                  {homeStats?.metrics[2]?.result && homeStats?.metrics[2]?.savedMoney ? (
                    <Stats>
                      <StatsOrg>{homeStats?.metrics[2]?.result} lbs of waste</StatsOrg> with a total savings of{' '}
                      <StatsBold>${homeStats?.metrics[2]?.savedMoney}.</StatsBold>
                    </Stats>
                  ) : null}
                </Left>
                {infoCard && Array.isArray(infoCard) ? <Vector src={infoCard[1]?.vector?.url} /> : null}
                <Right>
                  {infoCard && Array.isArray(infoCard) && infoCard[1]?.iconRight?.url ? (
                    <Icon src={infoCard[1]?.iconRight?.url} />
                  ) : null}
                  {homeStats?.metrics[2]?.equivalentValue ? (
                    <Stats>
                      That’s the equivalent of{' '}
                      <StatsGreen>{homeStats?.metrics[2]?.equivalentValue} garbage trucks</StatsGreen>
                      worth of diverted waste.
                    </Stats>
                  ) : null}
                </Right>
              </Card>

              <Card>
                <Left>
                  {infoCard && Array.isArray(infoCard) && infoCard[2]?.icon?.url ? (
                    <Icon src={infoCard[2]?.icon?.url} />
                  ) : null}
                  {homeStats?.metrics[1]?.result && homeStats?.metrics[1]?.savedMoney ? (
                    <Stats>
                      <StatsOrg>{homeStats?.metrics[1]?.result} kWh.</StatsOrg> with a total savings of{' '}
                      <StatsBold>${homeStats?.metrics[1]?.savedMoney}.</StatsBold>
                    </Stats>
                  ) : null}
                </Left>
                {infoCard && Array.isArray(infoCard) ? <Vector src={infoCard[2]?.vector?.url} /> : null}
                <Right>
                  {infoCard && Array.isArray(infoCard) && infoCard[2]?.iconRight?.url ? (
                    <Icon src={infoCard[2]?.iconRight?.url} />
                  ) : null}
                  {homeStats?.metrics[1]?.equivalentValue ? (
                    <Stats>
                      That’s the equivalent of{' '}
                      <StatsGreen>powering {homeStats?.metrics[1]?.equivalentValue} houses</StatsGreen>
                      for an entire year.
                    </Stats>
                  ) : null}
                </Right>
              </Card>

              <Card>
                <Left>
                  {infoCard && Array.isArray(infoCard) && infoCard[3]?.icon?.url ? (
                    <Icon src={infoCard[3]?.icon?.url} />
                  ) : null}
                  {homeStats?.metrics[4]?.result && homeStats?.metrics[4]?.savedMoney ? (
                    <Stats4>
                      <StatsOrg>{homeStats?.metrics[4]?.result} gallons of fuel per year,</StatsOrg> with a total
                      savings of <StatsBold>${homeStats?.metrics[4]?.savedMoney}.</StatsBold>
                    </Stats4>
                  ) : null}
                </Left>
                {infoCard && Array.isArray(infoCard) ? <Vector src={infoCard[3]?.vector?.url} /> : null}
                <Right>
                  {infoCard && Array.isArray(infoCard) && infoCard[3]?.iconRight?.url ? (
                    <Icon src={infoCard[3]?.iconRight?.url} />
                  ) : null}
                  {homeStats?.metrics[4]?.equivalentValue ? (
                    <Stats4b>
                      That’s the equivalent of{' '}
                      <StatsGreen>removing {homeStats?.metrics[4]?.equivalentValue} cars off the road.</StatsGreen>
                      for an entire year.
                    </Stats4b>
                  ) : null}
                </Right>
              </Card>
            </Carousel>
          ) : null}
        </CarouselContainer>
      </Content>
    </Container>
  )
}

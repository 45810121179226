import { ServiceResponse } from '@types'
import axios from 'axios'

export const getMetrics = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/report`)
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export const getMetricsByDate = async (start: string, end: string): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/report?start_date=${start}&end_date=${end}`)
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export const getMetricsOneYear = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/ifeveryonesaved`)
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export const getMetricsCommunity = async (): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/thirdparty`)
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

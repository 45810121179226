import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import {
  Container,
  Width,
  NavigationContainer,
  NavigationContent,
  Line,
  LinksContent,
  NavIconContainer,
  NavIcon,
  NavTitle,
  NavInfo,
  MainTitle,
  NavIcon2,
} from './styled'

export const NavigationSteps: FC = () => {
  const state = useSelector(staticSelector)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { Steps_cards, title } = state.content?.learnMore.steps

  return (
    <Container id="start-steps-navigator">
      <Width>
        {title ? <MainTitle>{title}</MainTitle> : null}
        <NavigationContainer>
          <NavigationContent>
            <Line />
            {state.content &&
              Steps_cards.map((item: any, index: string) => {
                return (
                  <>
                    <LinksContent>
                      <Link to={index.toString()} spy={true} smooth={true} offset={-70} duration={500}>
                        <NavIconContainer>{item.icon.url ? <NavIcon src={item.icon.url} /> : null}</NavIconContainer>
                        {item.stepNumber ? <NavTitle>{item.stepNumber}</NavTitle> : null}
                        {item.title ? <NavInfo>{item.title}</NavInfo> : null}
                      </Link>
                    </LinksContent>
                    <Line />
                  </>
                )
              })}
          </NavigationContent>
        </NavigationContainer>
      </Width>
    </Container>
  )
}

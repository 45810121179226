import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import { Container, Content, Description, Info, Title, Close, ImageContainer, Image, Button } from './styled'

export const StartTop: FC = () => {
  const state = useSelector(staticSelector)

  const { close, info, title, button, background } = state.content?.learnMore.top

  return (
    <Container>
      <Content>
        <Info>
          {title ? <Title>{title}</Title> : null}
          {info ? <Description dangerouslySetInnerHTML={{ __html: info }} /> : null}
          {close ? <Close>{close}</Close> : null}
          {button?.label ? (
            <Link to="start-steps-navigator" spy={true} smooth={true} offset={-70} duration={500}>
              <Button>{button?.label}</Button>
            </Link>
          ) : null}
        </Info>

        <ImageContainer>{background?.url ? <Image src={background?.url} /> : null}</ImageContainer>
      </Content>
    </Container>
  )
}

import { ServiceResponse } from '@types'
import axios from 'axios'
import { ContactValues } from 'ducks/contact'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const submitContact = async (body: ContactValues): Promise<ServiceResponse> => {
  try {
    await axios.post(`${process.env.REACT_APP_MAIN_API}/contact-emails/submit`, body)
    return (
      MySwal.fire({
        icon: 'success',
        title: 'Your message was sent!',
        showConfirmButton: true,
      }),
      { ok: true }
    )
  } catch (error: any) {
    return (
      MySwal.fire({
        icon: 'error',
        title: 'Something went wrong, please try again later',
        showConfirmButton: true,
      }),
      { ok: false, message: error.message }
    )
  }
}

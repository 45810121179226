import { ServiceResponse } from '@types'
import axios from 'axios'

const login = async (email: string, password: string): Promise<ServiceResponse> => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_MAIN_API}/api/login`, {
      email,
      password,
    })
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export default login

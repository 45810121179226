import { staticSelector } from 'ducks/static'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoaderMetrics } from '../LoaderMetrics'
import { MetricsSubTab1 } from '../MetricsSubTab1'
import { MetricsSubTab2 } from '../MetricsSubTab2'
import { MetricsSubTab3 } from '../MetricsSubTab3'
import { MetricsSubTab4 } from '../MetricsSubTab4'
import { MetricsSubTab5 } from '../MetricsSubTab5'
import { Container, Content, TabContainer, Icon, Tab, Tabs, TabList, TabPanel, Line } from './styled'
import './styleTab.css'

interface Props {
  metrics: Metric[]
}
interface Metric {
  id: number
  name: string
  result: string
  savedMoney: string
  equivalentValue: string
}

export const MetricsTab1: FC<Props> = ({ metrics }) => {
  const state = useSelector(staticSelector)

  const [activeTab, setActiveTab] = useState(0)

  const { subTabs } = state.content?.nationalMetric

  const handleTabs = () => {
    if (metrics?.length) {
      if (activeTab === 0)
        return (
          <MetricsSubTab1
            result={metrics[0]?.result}
            equivalentValue={metrics[0]?.equivalentValue}
            savedMoney={metrics[0]?.savedMoney}
          />
        )
      if (activeTab === 1)
        return (
          <MetricsSubTab2
            result={metrics[1]?.result}
            equivalentValue={metrics[1]?.equivalentValue}
            savedMoney={metrics[1]?.savedMoney}
          />
        )
      if (activeTab === 2)
        return (
          <MetricsSubTab3
            result={metrics[2]?.result}
            equivalentValue={metrics[2]?.equivalentValue}
            savedMoney={metrics[2]?.savedMoney}
          />
        )
      if (activeTab === 3)
        return (
          <MetricsSubTab4
            result={metrics[3]?.result}
            equivalentValue={metrics[3]?.equivalentValue}
            savedMoney={metrics[3]?.savedMoney}
          />
        )
      if (activeTab === 4)
        return (
          <MetricsSubTab5
            result={metrics[8]?.result}
            equivalentValue={metrics[8]?.equivalentValue}
            savedMoney={metrics[8]?.savedMoney}
          />
        )
    }
  }

  const clickReference = (index: string) => {
    document.getElementById(index)?.click()
  }

  return (
    <Container>
      <Content>
        <Tabs>
          <TabList>
            {subTabs && subTabs.length
              ? subTabs.map((item: any, index: number) => {
                  return (
                    <TabContainer key={index}>
                      {item.icon.url ? (
                        <Icon onClick={() => clickReference(index.toString())} src={item.icon.url} />
                      ) : null}
                      {item.tab ? (
                        <Tab
                          key={index}
                          id={index.toString()}
                          className={`tab ${activeTab === index ? 'active' : ''}`}
                          onClick={() => setActiveTab(index)}
                        >
                          {item.tab}
                        </Tab>
                      ) : null}
                    </TabContainer>
                  )
                })
              : null}
          </TabList>
          <Line />
          {metrics?.length ? <TabPanel>{handleTabs()}</TabPanel> : <LoaderMetrics />}
        </Tabs>
      </Content>
    </Container>
  )
}

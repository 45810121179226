import styled from 'styled-components'

export const Container = styled.div`
  height: 450px;
  // width: 100%;
  background-color: #409658;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1120px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 8vh;

  @media screen and (max-width: 1120px) {
    flex-direction: column-reverse;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  // margin-right: 200px;
  width: 30%;

  @media screen and (max-width: 1120px) {
    width: 40%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 900px) {
    width: 55%;
  }

  @media screen and (max-width: 610px) {
    width: 65%;
  }

  @media screen and (max-width: 480px) {
    width: 70%;
  }
`

export const Title = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 38px;
  line-height: 37px;
  color: #ffffff;
  text-align: left;

  @media screen and (max-width: 1120px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 610px) {
    // font-size: 28px;
    line-height: 30px;
  }
`

export const Extended = styled.div`
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  color: #ffffff;
  text-align: left;

  @media screen and (max-width: 610px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.36702px;
  }
`

export const ImageContainer = styled.div`
  // width: 50%;
  height: 525px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1120px) {
    height: 370px;
  }

  @media screen and (max-width: 610px) {
    height: 320px;
  }

  @media screen and (max-width: 480px) {
    height: 250px;
  }
`

export const Image = styled.img`
  height: 343px;
  width: 100%;
  // object-fit: cover;
  // display: block;
  // margin-left: auto;
  // margin-right: auto;

  @media screen and (max-width: 654px) {
    height: 300px;
  }

  @media screen and (max-width: 572px) {
    height: 250px;
  }

  @media screen and (max-width: 480px) {
    height: 200px;
  }
`

export const Button = styled.a`
  display: block;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  width: 214px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  ::after {
    content: 'ᐳ';
    margin-left: 40px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }

  @media screen and (max-width: 1120px) {
    margin-bottom: 30px;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media screen and (max-width: 476px) {
    width: 90%;
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media screen and (max-width: 476px) {
    width: 90%;
  }
`

export const Icon = styled.img`
  max-width: 54px;
  max-height: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`

export const Stats = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.0849767px;
  //margin-left: auto;
  //margin-right: auto;
`

export const Stats4 = styled(Stats)`
  width: 70%;
  margin: auto;
`

export const Stats4b = styled(Stats)`
  width: 75%;
  margin: auto;
`

export const StatsOrg = styled(Stats)`
  color: #dfa000;
`

export const StatsBold = styled(Stats)`
  font-weight: bold;
`

export const StatsGreen = styled(Stats)`
  color: #54ae2a;
`

export const Vector = styled.img`
  max-width: 8px;
  height: 20px;

  @media screen and (max-width: 476px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

export const Card = styled.div`
  width: 35rem;
  height: 15rem;
  background: #fefcf7;
  border-radius: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // margin-bottom: 25px;
  /*  box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: -22px 4px 41px -7px rgba(0, 0, 0, 0.13); */

  @media screen and (max-width: 583px) {
    width: 95vw;
    height: 30vh;
  }

  @media screen and (max-width: 476px) {
    flex-direction: column;
    width: 80vw;
    height: 22rem;
  }
`

export const CarouselContainer = styled.div`
  // display: flex;
  max-width: 35rem;
  border-radius: 37px;
  & > .carousel-root {
    display: flex;
    &:hover {
      border-radius: 37px;
    }
  }
  & .slider-wrapper {
    height: 100%;
  }
  & .slider-wrapper > .slider {
    height: 100%;
  }
  & .control-next {
    border-radius: 0px 37px 37px 0px;
  }
  & .control-prev {
    border-radius: 37px 0px 0px 37px;
  }

  @media screen and (max-width: 583px) {
    max-width: 95vw;
  }

  @media screen and (max-width: 476px) {
    max-width: 80vw;
  }
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  Title,
  SubTitle,
  Columns,
  ColumnTitle,
  ColumnText,
  Width,
  ColumnsContainer,
} from './styled'

export const StartShades: FC = () => {
  const state = useSelector(staticSelector)

  const { subTitle, title, firstColumn } = state.content?.learnMore.shades

  return (
    <Container>
      <Width>
        <Content>
          {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
          {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
          <ColumnsContainer>
            {state.content &&
              firstColumn.map((item: any, index: number) => {
                return (
                  <Columns key={`columns-shades-${index}`}>
                    {item.title ? <ColumnTitle>{item.title}</ColumnTitle> : null}
                    {item.description ? <ColumnText dangerouslySetInnerHTML={{ __html: item.description }} /> : null}
                  </Columns>
                )
              })}
          </ColumnsContainer>
        </Content>
      </Width>
    </Container>
  )
}

import React, { FC, useState } from 'react'
import { Community } from '../MetricsPage/types'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  Container,
  Content,
  NamesContainer,
  NamesTable,
  CardsContainer,
  Title,
  SubTitle,
  Name,
  TitleContainer,
  CarouselContainer,
} from './styled'
import { Card1 } from './Cards/Card1'
import { Card2 } from './Cards/Card2'
import { Card3 } from './Cards/Card3'
import { Card4 } from './Cards/Card4'
import { Card5 } from './Cards/Card5'
import { Card6 } from './Cards/Card6'
import { Card7 } from './Cards/Card7'
import { Card8 } from './Cards/Card8'

interface Props {
  community: Community
}

export const MetricsTab4SubTab3: FC<Props> = ({ community }) => {
  const { metrics } = community

  const [idDetails, setIdDetails] = useState<number>(0)

  const renderCards = (index: number) => {
    const cardsArray = [Card1, Card2, Card3, Card4, Card5, Card6, Card7, Card8]
    const cardIndex = []

    if (parseInt(metrics[index]?.greenhouse_money) >= 1) cardIndex.push(0)
    if (parseInt(metrics[index]?.electric_money) >= 1) cardIndex.push(1)
    if (parseInt(metrics[index]?.solid_waste_money) >= 1) cardIndex.push(2)
    if (parseInt(metrics[index]?.water_money) >= 1) cardIndex.push(3)
    if (parseInt(metrics[index]?.fuel_money) >= 1) cardIndex.push(4)
    if (parseInt(metrics[index]?.hazardous_money) >= 1) cardIndex.push(5)
    if (parseInt(metrics[index]?.voc) >= 1) cardIndex.push(6)
    if (parseInt(metrics[index]?.therms_money) >= 1) cardIndex.push(7)

    return cardIndex.map((card) => React.createElement(cardsArray[card], { data: metrics[index] }))
  }

  return (
    <Container>
      <Content>
        <NamesContainer>
          {metrics?.length
            ? metrics?.map((item, index) => (
                <NamesTable onClick={() => setIdDetails(index)} key={`community-${index}`}>
                  {item?.business_name}
                </NamesTable>
              ))
            : null}
          <NamesTable></NamesTable>
        </NamesContainer>
        <CardsContainer>
          <TitleContainer>
            <Title>Individual business data for:</Title>
            {metrics?.length ? <Name>{metrics[idDetails].business_name}</Name> : null}
          </TitleContainer>
          <SubTitle />
          <CarouselContainer>
            <Carousel
              autoPlay
              infiniteLoop
              interval={4000}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              stopOnHover={false}
              emulateTouch
              centerMode
              showThumbs={false}
              autoFocus
              centerSlidePercentage={100}
            >
              {renderCards(idDetails)}
            </Carousel>
          </CarouselContainer>
        </CardsContainer>
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  // height: 712px;
  width: 100%;
  background-color: #dbcfb8;
`

export const Content = styled.div`
  height: 712px;
  max-width: 1250px;
  margin: auto;
`

export const TabList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 67%;
  height: 46px;
  list-style-type: none;
  background-color: #062a20;
  cursor: pointer;
  border-radius: 20px 20px 0px 0px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
`

export const Tab = styled.li`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  justify-content: center;
  line-height: 91.5%;
  color: #eff3e5;
  width: 30%;
  height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
`

export const Tabs = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0px 20px 0px 0px;
`

export const TabListContainer = styled.div`
  width: 70%;
  border-top-right-radius: 20px;
`

export const Line = styled.span`
  border-left: 1px solid #fbf7ef;
  height: 27px;
  align-self: center;
  /*   margin-left: 30px;
  margin-right: 30px; */
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 91.5%;
  color: #062a20;
  margin-top: 40px;
  margin-bottom: 20px;
`

export const TabsContainer = styled.div``

export const InfoContainer = styled.div`
  // height: 603px;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Info = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.106607px;
  color: #7d8c9a;
`

export const BusinessContainer = styled.div`
  height: 63px;
  background-color: #eee6d8;
  border-radius: 0px 0px 20px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 91.5%;
  color: #062a20;
`

export const Business = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 91.5%;
  color: #062a20;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;

  //border: solid 1px red;
`

export const Range = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 91.5%;
  color: #062a20;
  // padding-left: 10px;
  padding-right: 5px;
`

export const DateInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  border: solid 1.5px #062a20;
  border-radius: 30px 0px 0px 30px;
  border: 1px solid #062a20;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
`

export const Vector = styled.img`
  height: 8px;
  padding-right: 10px;
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 20px;
`

export const Calendar = styled.img`
  height: 14px;
  padding-left: 10px;
  padding-right: 5px;
`

export const TabPanel = styled.div`
  background-color: #fffbf4;
  height: 603px;
  border-top-right-radius: 20px;
`

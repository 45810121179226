import styled from 'styled-components'

export const Container = styled.div`
  height: 525px;
  width: 100%;
  background-color: #fffbf4;

  @media screen and (max-width: 1140px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  // flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: 1140px) {
    flex-direction: column;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  // margin-right: 200px;
  width: 35%;
  margin-top: -100px;

  @media screen and (max-width: 1140px) {
    margin-top: 30px;
    width: 70%;
  }
`

export const Title = styled.h2`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 38px;
  line-height: 40px;
  color: #409658;
  text-align: left;

  @media screen and (max-width: 660px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const Extended = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  color: #000000;
  text-align: left;
`

export const ImageContainer = styled.div`
  // width: 100%;
  height: 525px;
  // margin-top: 50px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1140px) {
    height: 100%;
    margin-top: 50px;
  }
`

export const Image = styled.img`
  height: 400px;
  max-width: 100%;
  // object-fit: cover;
  // display: block;
  // margin-left: auto;
  // margin-right: auto;

  @media screen and (max-width: 660px) {
    height: 300px;
  }

  @media screen and (max-width: 470px) {
    height: 250px;
  }

  @media screen and (max-width: 390px) {
    height: 230px;
  }
`

export const Button = styled.a`
  display: block;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  float: right;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-top: 30px;
  margin-right: 100px;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
  ::after {
    content: 'ᐳ';
    padding-left: 9px;
  }

  @media screen and (max-width: 1140px) {
    margin-bottom: 40px;
  }
`

export const MapContainer = styled.div`
  // width: 700px;
  // margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  // margin-right: 40px;
`

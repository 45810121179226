import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const link = css`
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 5px;
  letter-spacing: 0.28px;
  color: #062a20;
  margin: 0px 16px;
  &:hover {
    color: #dfa000;
    text-decoration: underline;
  }
`

export const Container = styled.header`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #faf4e7;
  top: 1px;
  height: 60px;
  /* position: -webkit-sticky;
  position: sticky;  
  width: 100%;  
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
`
export const Content = styled.div`
  background-color: #faf4e7;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 60px;
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const LogoContainer = styled.div`
  margin-left: 50px;
  @media screen and (max-width: 1079px) {
    margin-left: 45%;
  }
`

export const Logo = styled.img`
  // margin-left: -100px;
  width: 119.4px;
  height: 37.61px;
  // left: 104px;
  top: 8px;

  @media screen and (max-width: 1079px) {
    width: 34px;
    height: 34px;
  }
`

export const Options = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 752px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 20%;
    height: 400%;
    background-color: white;
  }
`

export const ExternalLink = styled.a`
  ${link}
`

export const InternalLink = styled(Link)`
  ${link}
`

export const Menu = styled.div`
  display: none;
  @media screen and (max-width: 752px) {
    display: block;
    position: absolute;
    right: 10px;
    top: 30px;
  }
`

export const MenuItem = styled.div`
  width: 20px;
  height: 3px;
  background-color: black;
  margin-bottom: 4px;
`

export const LoginContainer = styled.div`
  width: 150px;
  height: 100%;
  // background-color: #409658;
  display: flex;
  justify-content: center;
  /*  &:hover {
    background-color: #409658;
  } */
`

export const Login = styled.a`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #062a20;
  &:hover {
    color: #dfa000;
    text-decoration: underline;
  }
`

export const Line = styled.span`
  border-left: 1px solid #000000;
  height: 27px;
  align-self: center;
  margin-left: 10px;
  // margin-right: 10px;
`

import React, { FC } from 'react'
import { Container, Content, Logo, Message, Image, ImageContainer, MessageContainer } from './styled'
// import LogoPNG from 'assets/loaderGreenBiz.png'
import logo from 'assets/logo404.png'
import map from 'assets/map404.png'

export const NotFound: FC = () => {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image src={map} />
        </ImageContainer>
        <MessageContainer>
          <Logo src={logo} />
          <Message>Sorry, page not found</Message>
        </MessageContainer>
      </Content>
    </Container>
  )
}

import styled, { keyframes } from 'styled-components'
import LogoPNG from 'assets/loaderGreenBiz.png'

export const Container = styled.div`
  min-height: 663px;
  background-color: #fffbf4;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1050px) {
    min-height: 863px;
  }
  @media screen and (max-width: 680px) {
    min-height: 1000px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`

export const WidthMap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 685px;
`

export const WidthInfo = styled.div`
  // max-width: 685px;
`

export const MapSection = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fffbf4;
  flex: 1;

  @media screen and (max-width: 1050px) {
    justify-content: center;
  }

  @media screen and (max-width: 610px) {
  }
`
export const MapContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 610px) {
    // max-height: 400px;
  }

  @media screen and (max-width: 585px) {
    margin-top: 0;
  }

  @media screen and (max-width: 490px) {
  }
`

export const TextContainer = styled.div`
  margin-left: 2rem;
  // margin-bottom: 30px;
  margin-top: 3rem;

  @media screen and (max-width: 400px) {
    margin-left: 0.5rem;
  }
`

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  display: flex;
  align-items: center;
  color: #fffbf4;
`

export const SubTitle = styled.div`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.07px;
  color: #fffbf4;
  margin-top: 10px;
`

export const InfoSection = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fffbf4;
  width: 35%;

  @media screen and (max-width: 1050px) {
    width: 65%;
    flex: 0;
  }
  @media screen and (max-width: 650px) {
    width: 90%;
  }
`

export const IntroMessageContainer = styled.div`
  display: flex;
  height: 100%;
  animation: fadein 0.7s linear 1 forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const IntroMessage = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 42px;
  display: flex;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 340px) {
    font-size: 18px;
  }
`

export const CardStates = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  margin-top: 2rem;
  animation: fadein 0.5s linear 1 forwards;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1050px) {
    padding-bottom: 4rem;
  }
`

export const IconContainer = styled.div`
  width: 60%;
`

export const Icon = styled.img`
  max-height: 101px;
  max-width: 80%;
`

export const InfoTitle = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #062a20;
`

export const InfoDescription = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #062a20;
  margin-bottom: 30px;
`

export const Button = styled.a`
  display: block;
  color: #062a20;
  background-color: #ffc42d;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #062a20;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-bottom: 20px;

  ::after {
    content: 'ᐳ';
    margin-left: 20px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

const glowing = keyframes`
  0% { opacity: 0.7; transform: scale(0.9) }
  50% { opacity: 1; transform: scale(1);  }
  100% { opacity: 0.7; transform: scale(0.9) }
`

export const Logo = styled.img`
  width: 100px;
  height: 100px;
  animation: ${glowing} 1.5s ease-in-out infinite forwards;
`

Logo.defaultProps = {
  src: LogoPNG,
}

export const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1050px) {
    top: 30%;
    left: 50%;
  }
`

export const LinkToFile = styled.a`
  text-decoration: underline;
  &:hover {
    color: #409658;
  }
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Image,
  ContentContainer,
  Title,
  ImageContainer,
  Content,
} from './styled'

export const Intro: FC = () => {
  const state = useSelector(staticSelector)

  const {
    background: { url },
    card: [
      {
        description,
        title,
        link: [{ label, path }],
      },
    ],
  } = state.content.home.intro[0]

  return (
    <Container>
      <Content>
        <ImageContainer>{url ? <Image src={url} /> : null}</ImageContainer>
        <Card>
          <CardHeader>{title ? <Title>{title}</Title> : null}</CardHeader>
          <ContentContainer>{description ? <CardContent>{description}</CardContent> : null}</ContentContainer>
          {path && label ? <Button href={path}>{label}</Button> : null}
        </Card>
      </Content>
    </Container>
  )
}

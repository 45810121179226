import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  Title,
  CardDesign,
  Left,
  LeftIconContainer,
  Icon,
  TextContainer,
  Info,
  Vector,
  Right,
  RightIconContainer,
  InfoGrey,
  InfoGreen,
} from '../styledCard'
import vector from 'assets/Vector6.png'
import { Metric } from '../../MetricsPage/types'

interface Props {
  data: Metric
}

export const Card3: FC<Props> = ({ data }) => {
  const state = useSelector(staticSelector)

  const { title, iconLeft, iconRight, textLeft, textRight } = state.content?.nationalMetric.subTabRender[2]

  return (
    <Container>
      <Content>
        {/* {title ? <Title>{title}</Title> : null} */}
        <CardDesign>
          <Left>
            <LeftIconContainer leftColor="#998A7F">
              {iconLeft.url ? <Icon src={iconLeft.url} /> : null}
            </LeftIconContainer>
            <TextContainer>
              <>
                <Info>
                  <InfoGrey leftColor="#998A7F">{data.solid_waste} lbs of waste</InfoGrey>
                  Total $ savings of <InfoGrey leftColor="#998A7F">${data.solid_waste_money}.</InfoGrey>
                </Info>
              </>
            </TextContainer>
          </Left>
          <Vector src={vector} />
          <Right>
            <RightIconContainer rightColor="#54AE2A">
              {iconRight.url ? <Icon src={iconRight.url} /> : null}
            </RightIconContainer>
            <TextContainer>
              <Info>
                That’s the equivalent of <InfoGreen>{data.solid_waste_visual} garbage trucks</InfoGreen> worth of
                diverted waste
              </Info>
            </TextContainer>
          </Right>
        </CardDesign>
      </Content>
    </Container>
  )
}

import { metricsSelector } from 'ducks/metrics'
import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  BackgroundDeg,
  Card,
  Container,
  Content,
  Icon,
  Info,
  InfoGreen,
  InfoGrey,
  Left,
  LeftIconContainer,
  Right,
  RightIconContainer,
  TextContainer,
  Title,
  Vector,
} from './styled'
import iconFuel from '../../../assets/icon_fuel.png'
import iconDrum from '../../../assets/icon_drum.png'
import iconHazardous from '../../../assets/icon_hazardous.png'
import iconVoc from '../../../assets/icon_voc.png'
import vector from 'assets/Vector6.png'
import { Community } from '../MetricsPage/types'

interface Props {
  result: string
  equivalentValue: string
  savedMoney: string
  community: Community
}

export const MetricsTab4SubTab2: FC<Props> = ({ result, equivalentValue, savedMoney, community }) => {
  const state = useSelector(staticSelector)
  const { totals } = community
  const { iconLeft, iconRight } = state.content?.nationalMetric?.subTabRender[0]
  const { iconLeft: iconBulb, iconRight: iconHouseBulb } = state.content?.nationalMetric?.subTabRender[1]
  const { iconLeft: iconBin, iconRight: iconTruck } = state.content?.nationalMetric?.subTabRender[2]
  const { iconLeft: iconWater, iconRight: iconTub } = state.content?.nationalMetric?.subTabRender[3]
  const { iconLeft: iconFire, iconRight: iconHouseFire } = state.content?.nationalMetric?.subTabRender[4]

  return (
    <Container>
      <Content>
        <Title>This is an example of community data shared using five test businesses.</Title>
        {parseInt(totals?.greenhouse_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#7D8C9A">
                {iconLeft.url ? <Icon src={iconLeft.url} /> : null}
              </LeftIconContainer>
              <TextContainer>
                {totals?.greenhouse ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#7D8C9A">{totals?.greenhouse} lbs of CO2</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#7D8C9A">${totals?.greenhouse_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconRight.url ? <Icon src={iconRight.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.greenhouse_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>planting {totals?.greenhouse_visual} acres</InfoGreen> of urban
                    trees
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.electric_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#DFA000">{iconBulb ? <Icon src={iconBulb.url} /> : null}</LeftIconContainer>
              <TextContainer>
                {totals?.electric ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#DFA000">{totals?.electric} kWh</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#DFA000">${totals?.electric_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconHouseBulb.url ? <Icon src={iconHouseBulb.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.electric_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>powering {totals?.electric_visual} houses</InfoGreen> for an
                    entire year
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.solid_waste_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#998A7F">
                {iconBin.url ? <Icon src={iconBin.url} /> : null}
              </LeftIconContainer>
              <TextContainer>
                {totals?.solid_waste ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#998A7F">{totals?.solid_waste} lbs of waste</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#998A7F">${totals?.solid_waste_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconTruck.url ? <Icon src={iconTruck.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.solid_waste_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>{totals?.solid_waste_visual} garbage trucks</InfoGreen> worth of
                    diverted waste
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.water_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#38BBE5">
                {iconWater.url ? <Icon src={iconWater.url} /> : null}
              </LeftIconContainer>
              <TextContainer>
                {totals?.water ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#38BBE5">{totals?.water} gallons of water</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#38BBE5">${totals?.water_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconTub.url ? <Icon src={iconTub.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.water_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>{totals?.water_visual} bathtubs</InfoGreen> of fresh drinking
                    water
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.fuel_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#FF8F28">{iconFuel ? <Icon src={iconFuel} /> : null}</LeftIconContainer>
              <TextContainer>
                {totals?.fuel ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#FF8F28">{totals?.fuel} gallons of fuel</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#FF8F28">${totals?.fuel_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">{iconFuel ? <Icon src={iconFuel} /> : null}</RightIconContainer>
              <TextContainer>
                {totals?.fuel_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>removing {totals?.fuel_visual} cars</InfoGreen> off the road
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.hazardous_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#232221">
                {iconHazardous ? <Icon src={iconHazardous} /> : null}
              </LeftIconContainer>
              <TextContainer>
                {totals?.hazardous ? (
                  <>
                    {' '}
                    <Info>
                      <InfoGrey leftColor="#232221">{totals?.hazardous} gallons of hazardous waste</InfoGrey>
                      Total $ savings of <InfoGrey leftColor="#232221">${totals?.hazardous_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">{iconDrum ? <Icon src={iconDrum} /> : null}</RightIconContainer>
              <TextContainer>
                {totals?.hazardous_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>{totals?.hazardous_visual} fifty-five gallon drums</InfoGreen>{' '}
                    hazardous waste
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.voc) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#777777">{iconVoc ? <Icon src={iconVoc} /> : null}</LeftIconContainer>
              <TextContainer>
                {totals?.voc ? (
                  <>
                    <Info>
                      <InfoGrey leftColor="#777777">
                        {totals?.voc} lbs of volatile organic
                        <br />
                        compounds
                      </InfoGrey>
                      (VOCs)
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconTruck ? <Icon src={iconTruck.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.voc_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>{totals?.voc_visual} lbs of VOCs prevented</InfoGreen> from
                    contributing to ground <br />
                    level ozone smog
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
        {parseInt(totals?.therms_money) >= 1 ? (
          <Card>
            <Left>
              <LeftIconContainer leftColor="#EF4136">
                {iconFire.url ? <Icon src={iconFire.url} /> : null}
              </LeftIconContainer>
              <TextContainer>
                {totals?.therms ? (
                  <>
                    <Info>
                      <InfoGrey leftColor="#EF4136">{totals?.therms} therms of natural gas</InfoGrey>
                      per year. Total $ savings of <InfoGrey leftColor="##EF4136">${totals?.therms_money}.</InfoGrey>
                    </Info>
                  </>
                ) : null}
              </TextContainer>
            </Left>
            <Vector src={vector} />
            <Right>
              <RightIconContainer rightColor="#54AE2A">
                {iconHouseFire.url ? <Icon src={iconHouseFire.url} /> : null}
              </RightIconContainer>
              <TextContainer>
                {totals?.therms_visual ? (
                  <Info>
                    That’s the equivalent of <InfoGreen>heating {totals?.therms_visual} homes</InfoGreen>
                    for an entire year
                  </Info>
                ) : null}
              </TextContainer>
            </Right>
          </Card>
        ) : null}
      </Content>
      {/* <BackgroundDeg></BackgroundDeg> */}
    </Container>
  )
}

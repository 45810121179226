import { staticSelector } from 'ducks/static'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  MapSection,
  Title,
  SubTitle,
  InfoSection,
  Icon,
  InfoTitle,
  InfoDescription,
  Button,
  CardStates,
  MapContainer,
  IconContainer,
  TextContainer,
  WidthMap,
  WidthInfo,
  IntroMessage,
  IntroMessageContainer,
  Diamond,
} from './styled'
import USAMap from 'react-usa-map'
import './states.css'

export const States: FC = () => {
  const state = useSelector(staticSelector)

  const { title, subtitle, introMessage, introMessage2, stateInfo } = state.content.nationalState

  const [show, setShow] = useState<any>()

  const statesCustomConfig = () => {
    const obj: any = {}
    stateInfo.forEach((e: any) => {
      const { code } = e
      const element: any = document.querySelector(`path.${code}`)
      if (element) {
        element.setAttribute('data-interactive', 'on')
      }
      obj[code] = {
        fill: '#D5E4BC',
        clickHandler: () => setShow(e),
        cursor: 'pointer',
      }
    })
    return obj

    /* return {
      CA: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[0]),
        cursor: 'pointer',
      },
      NV: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[1]),
      },
      OR: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[2]),
      },
      WA: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[3]),
      },
      AZ: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[4]),
      },
      CO: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[5]),
      },
      IL: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[6]),
      },
      VA: {
        fill: '#D5E4BC ',
        clickHandler: () => setShow(stateInfo[7]),
      },
    } */
  }

  return (
    <Container>
      <Content>
        <MapSection>
          <WidthMap>
            <TextContainer>
              {title ? <Title>{title}</Title> : null}
              {/* {subtitle ? <SubTitle>{subtitle}</SubTitle> : null} */}
            </TextContainer>
            <MapContainer className="States">
              <USAMap defaultFill="#FAF4E7" customize={statesCustomConfig()} width={600} height={400} />
              <Diamond />
            </MapContainer>
          </WidthMap>
        </MapSection>
        <InfoSection>
          <WidthInfo>
            {!show ? (
              <IntroMessageContainer>
                <IntroMessage>{`${introMessage} ${stateInfo.length} ${introMessage2}`}</IntroMessage>
              </IntroMessageContainer>
            ) : (
              <CardStates>
                <IconContainer>{show?.icon?.url ? <Icon src={show?.icon.url} /> : null}</IconContainer>
                {show?.title ? <InfoTitle>{show?.title}</InfoTitle> : null}
                {show?.info ? <InfoDescription>{show?.info}</InfoDescription> : null}
                {show?.button?.path ? (
                  <Button href={show?.button?.path} target="_blank">
                    {show?.button.label}
                  </Button>
                ) : null}
              </CardStates>
            )}
          </WidthInfo>
        </InfoSection>
      </Content>
    </Container>
  )
}

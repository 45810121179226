import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const DateInput = styled.input`
  border: 1.5px solid #062a20;
  box-sizing: border-box;
  border-radius: 30px;
`

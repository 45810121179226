import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #dacdb6;
  display: flex;
  align-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media screen and (max-width: 1086px) {
    flex-direction: column;
  }
`

export const Message = styled.h2`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #062a20;
`

export const Logo = styled.img`
  height: 174px;

  @media screen and (max-width: 722px) {
    height: 100px;
  }

  @media screen and (max-width: 526px) {
    height: 90px;
  }
`

export const Image = styled.img`
  height: 410px;

  @media screen and (max-width: 722px) {
    height: 300px;
  }

  @media screen and (max-width: 526px) {
    height: 200px;
  }

  @media screen and (max-width: 358px) {
    height: 180px;
  }
`

export const ImageContainer = styled.div``

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  // width: 100%;
  background-color: #faf4e7;
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // height: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  column-gap: 70px;

  @media screen and (max-width: 1390px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media screen and (max-width: 1390px) {
    width: 80%;
    margin-bottom: 2rem;
  }
`

export const Title = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #000000;
`

export const Info = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.07px;
  color: #000000;
`

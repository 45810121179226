import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  padding-top: 40px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: auto;
`
export const NamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 340px;
  background-color: white;
  box-shadow: 0px 5.31587px 19.9345px 2.65794px rgba(160, 156, 145, 0.17);
  border-radius: 6px;
  overflow-y: auto;
  padding-top: 5px;
`

export const NamesTable = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &:hover {
    color: #54ae2a;
    font-weight: bold;
  }
`

export const CardsContainer = styled.div`
  width: 80%;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`

export const Title = styled.div``

export const Name = styled(Title)`
  color: #54ae2a;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  font-size: 22px;
`

export const SubTitle = styled.div``

export const CarouselContainer = styled.div`
  max-width: 650px;
  border-radius: 37px;
  margin-left: 5vw;
  margin-top: 2rem;
  box-shadow: 0px 5.31587px 19.9345px 2.65794px rgba(160, 156, 145, 0.17);
  // min-height: 196px;

  & > .carousel-root {
    display: flex;
    &:hover {
      border-radius: 50px;
    }
  }
  & .slider-wrapper {
    height: 100%;
  }
  & .slider-wrapper > .slider {
    height: 100%;
  }
  & .control-next {
    border-radius: 0px 50px 50px 0px;
  }
  & .control-prev {
    border-radius: 50px 0px 0px 50px;
  }
`

import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  Title,
  CardDesign,
  Left,
  LeftIconContainer,
  Icon,
  TextContainer,
  Info,
  Vector,
  Right,
  RightIconContainer,
  InfoGrey,
  InfoGreen,
  Icon2,
} from '../styledCard'
import vector from 'assets/Vector6.png'
import iconDrum from 'assets/icon_drum.png'
import iconHazardous from 'assets/icon_hazardous.png'
import { Metric } from '../../MetricsPage/types'

interface Props {
  data: Metric
}

export const Card6: FC<Props> = ({ data }) => {
  const state = useSelector(staticSelector)

  const { title, iconLeft, iconRight, textLeft, textRight } = state.content?.nationalMetric.subTabRender[3]

  return (
    <Container>
      <Content>
        {/* {title ? <Title>{title}</Title> : null} */}
        <CardDesign>
          <Left>
            <LeftIconContainer leftColor="#232221">
              {iconHazardous ? <Icon src={iconHazardous} /> : null}
            </LeftIconContainer>
            <TextContainer>
              <>
                <Info>
                  <InfoGrey leftColor="#232221">{data.hazardous} gallons of hazardous waste</InfoGrey>
                  Total $ savings of <InfoGrey leftColor="#232221">${data.hazardous_money}.</InfoGrey>
                </Info>
              </>
            </TextContainer>
          </Left>
          <Vector src={vector} />
          <Right>
            <RightIconContainer rightColor="#54AE2A">{iconDrum ? <Icon2 src={iconDrum} /> : null}</RightIconContainer>
            <TextContainer>
              <Info>
                That’s the equivalent of <InfoGreen>{data.hazardous_visual} fifty-five gallon drums</InfoGreen> of
                hazardous waste
              </Info>
            </TextContainer>
          </Right>
        </CardDesign>
      </Content>
    </Container>
  )
}

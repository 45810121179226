import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 20px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
`
export const Title = styled.p`
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  padding-left: 45px;
`

export const FirstText = styled.p`
  width: 90%;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  padding-left: 45px;
`
export const ContactUs = styled.a`
  display: flex;
  justify-content: center;
  width: 52%;
  font-size: 16px;
  color: #000000;
  line-height: 21px;
  text-decoration: underline;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 45px;
  margin-top: 30px;
`
export const Card = styled.div`
  width: 35rem;
  height: 15rem;
  background: white;
  border-radius: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Button = styled.a`
  display: block;
  color: #062a20;
  background-color: #ffc42d;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 52px;

  ::after {
    content: 'ᐳ';
    margin-left: 20px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

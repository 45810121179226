import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  Content,
  Title,
  Card,
  Left,
  LeftIconContainer,
  Icon,
  TextContainer,
  Info,
  Vector,
  Right,
  RightIconContainer,
  InfoBrown,
  InfoGreen,
} from './styled'
import vector from 'assets/Vector6.png'

interface Props {
  result: string
  equivalentValue: string
  savedMoney: string
}

export const MetricsSubTab3: FC<Props> = ({ result, equivalentValue, savedMoney }) => {
  const state = useSelector(staticSelector)

  const { title, iconLeft, iconRight, textLeft, textRight } = state.content?.nationalMetric.subTabRender[2]

  return (
    <Container>
      <Content>
        {title ? <Title>{title}</Title> : null}
        <Card>
          <Left>
            <LeftIconContainer>{iconLeft.url ? <Icon src={iconLeft.url} /> : null}</LeftIconContainer>
            <TextContainer>
              {result && savedMoney ? (
                <Info>
                  <InfoBrown>{result} lbs of waste</InfoBrown> with a total savings of{' '}
                  <InfoBrown>${savedMoney}</InfoBrown>
                </Info>
              ) : null}
            </TextContainer>
          </Left>
          <Vector src={vector} />
          <Right>
            <RightIconContainer>{iconRight.url ? <Icon src={iconRight.url} /> : null}</RightIconContainer>
            <TextContainer>
              {equivalentValue ? (
                <Info>
                  Thats the equivalent of <InfoGreen>{equivalentValue} garbage trucks</InfoGreen> worth of diverted
                  waste.
                </Info>
              ) : null}
            </TextContainer>
          </Right>
        </Card>
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

interface IconColor {
  leftColor?: string
  rightColor?: string
}

export const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-top: 40px;
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dbcfb8;
    border-radius: 23px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #dbcfb8;
    border-radius: 23px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: auto;
`

export const Title = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 91.5%;
  color: #062a20;
  text-align: center;
  margin-bottom: 30px;
  margin-left: 10px;
`

export const Card = styled.div`
  height: 212px;
  width: 547px;
  margin-bottom: 24px;
  background: #fefcf7;
  box-shadow: 0px 5.01818px 18.8182px 2.50909px rgba(160, 156, 145, 0.17);
  border-radius: 50.1818px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LeftIconContainer = styled.div<IconColor>`
  height: 92px;
  width: 92px;
  background-color: ${(props) => props.leftColor};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`

export const Icon = styled.img`
  height: 35px;
`

export const TextContainer = styled.div`
  // width: 100%;
  /* display: flex;
  flex-direction: row; */
`

export const Info = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.106607px;
`

export const InfoGrey = styled(Info)<IconColor>`
  color: ${(props) => props.leftColor};
`

export const InfoGreen = styled(Info)`
  color: #54ae2a;
`

export const Vector = styled.img`
  height: 27px;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RightIconContainer = styled.div<IconColor>`
  height: 92px;
  width: 92px;
  background-color: ${(props) => props.rightColor};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`
export const BackgroundDeg = styled.div`
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d3cab2 100%);
  height: 153px;
`

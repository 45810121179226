import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { Container, Content, VideoContainer, InfoContainer, Info, VideoWrapper } from './styled'

export const StartVideo: FC = () => {
  const state = useSelector(staticSelector)

  const { info, embed } = state.content?.learnMore.video

  return (
    <Container>
      <Content>
        <VideoContainer>{embed ? <ReactPlayer url={embed} wrapper={VideoWrapper} /> : null}</VideoContainer>
        <InfoContainer>{info ? <Info>{info}</Info> : null}</InfoContainer>
      </Content>
    </Container>
  )
}

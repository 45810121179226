import { staticSelector } from 'ducks/static'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import homeIcon from '../../../assets/homeIcon.png'
import equalSign from '../../../assets/equalSign.png'
import iconTruck from '../../../assets/iconTruck.png'
import iconTub from '../../../assets/iconTub.png'
import iconTrees from '../../../assets/iconTrees.png'
import {
  Container,
  Content,
  TitleContainer,
  Title,
  CallActionContainer,
  CallAction,
  Button,
  ButtonContainer,
  PhotoSection,
  LeftContainer,
  Card,
  RightContainer,
  TextContainer,
  RightText,
  LeftCard,
  Numbers,
  LeftTextCard,
  MiddleIcon,
  RightCard,
  RightTextCard,
  RightIcon,
  Card2,
  RightIcon2,
  LeftTextCard2,
  RightTextCard2,
  Card3,
  RightTextCard3,
  RightIcon3,
  Card4,
  LeftTextCard4,
  RightTextCard4,
  Numbers3,
  MiddleIcon3,
  MiddleIcon4,
  MiddleIcon1,
} from './styled'
import { metricsSelector } from 'ducks/metrics'

export const MetricsTab3: FC = () => {
  const state = useSelector(staticSelector)
  const { homeStats } = useSelector(metricsSelector)

  const { title, subtitle, button, rightColumn } = state.content?.nationalMetric.Tab3Content

  return (
    <Container>
      <Content>
        <LeftContainer>
          <TitleContainer>{title ? <Title>{title}</Title> : null}</TitleContainer>
          <CallActionContainer>{subtitle ? <CallAction>{subtitle}</CallAction> : null}</CallActionContainer>
        </LeftContainer>
        <PhotoSection>
          <Carousel
            autoPlay
            infiniteLoop
            interval={3000}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            stopOnHover={false}
            emulateTouch
            centerMode
            showThumbs={false}
            autoFocus
            centerSlidePercentage={100}
          >
            <Card>
              <LeftCard>
                {homeStats?.metrics[1]?.result ? <Numbers>{homeStats?.metrics[1]?.result}</Numbers> : null}
                <LeftTextCard>kWh.</LeftTextCard>
              </LeftCard>
              <MiddleIcon1 src={equalSign} />
              <RightCard>
                {homeStats?.metrics[1]?.equivalentValue ? (
                  <Numbers>{homeStats?.metrics[1]?.equivalentValue}</Numbers>
                ) : null}
                <RightTextCard>Houses powered for a year</RightTextCard>
              </RightCard>
              <RightIcon src={homeIcon} />
            </Card>
            <Card2>
              <LeftCard>
                {homeStats?.metrics[2]?.result ? <Numbers>{homeStats?.metrics[2]?.result}</Numbers> : null}
                <LeftTextCard2>Pounds of waste diverted</LeftTextCard2>
              </LeftCard>
              <MiddleIcon src={equalSign} />
              <RightCard>
                {homeStats?.metrics[2]?.equivalentValue ? (
                  <Numbers>{homeStats?.metrics[2]?.equivalentValue}</Numbers>
                ) : null}
                <RightTextCard2>Garbage trucks of waste diverted</RightTextCard2>
              </RightCard>
              <RightIcon2 src={iconTruck} />
            </Card2>
            <Card3>
              <LeftCard>
                {homeStats?.metrics[0]?.result ? <Numbers3>{homeStats?.metrics[0]?.result}</Numbers3> : null}
                <LeftTextCard>Pounds of CO2</LeftTextCard>
              </LeftCard>
              <MiddleIcon3 src={equalSign} />
              <RightCard>
                {homeStats?.metrics[0]?.equivalentValue ? (
                  <Numbers>{homeStats?.metrics[0]?.equivalentValue}</Numbers>
                ) : null}
                <RightTextCard3>Acres of trees planted</RightTextCard3>
              </RightCard>
              <RightIcon3 src={iconTrees} />
            </Card3>
            <Card4>
              <LeftCard>
                {homeStats?.metrics[3]?.result ? <Numbers>{homeStats?.metrics[3]?.result}</Numbers> : null}
                <LeftTextCard4>Gallons of water</LeftTextCard4>
              </LeftCard>
              <MiddleIcon4 src={equalSign} />
              <RightCard>
                {homeStats?.metrics[3]?.equivalentValue ? (
                  <Numbers>{homeStats?.metrics[3]?.equivalentValue}</Numbers>
                ) : null}
                <RightTextCard4>Bathtubs of water per hour</RightTextCard4>
              </RightCard>
              <RightIcon src={iconTub} />
            </Card4>
          </Carousel>
        </PhotoSection>
        <RightContainer>
          <TextContainer>
            <RightText>{rightColumn}</RightText>
          </TextContainer>
          <ButtonContainer>
            {button[0].label && button[0].path ? <Button href={button[0].path}>{button[0].label}</Button> : null}
          </ButtonContainer>
        </RightContainer>
      </Content>
    </Container>
  )
}

import { staticSelector } from 'ducks/static'
import useMediaQuery from 'hooks/useMediaQuery'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  Bottom,
  Container,
  Options,
  SocialIcon,
  SocialLink,
  Socials,
  Column,
  TitleCol,
  Item,
  LinkExternal,
  SubContainer,
  Icons,
  Line,
  Content,
  SocialContainer,
  SocialIcons,
  ColumnsContainer,
  CopyrightContainer,
  Copyright,
  SubContainerResponsive,
} from './styled'

export const Footer: FC = () => {
  const state = useSelector(staticSelector)
  const matches = useMediaQuery('(min-width: 960px)')

  return (
    <Container>
      <Content>
        {matches ? (
          state.content.footer && (
            <Options>
              <Socials>
                {state.content.footer.social && state.content.footer.social.length ? (
                  <Icons>
                    {state.content.footer.social?.map((item: any, index: number) => {
                      return (
                        <SocialLink key={`footer-social-icons-${index}`} href={item.url} target="blank">
                          <SocialIcon src={item.icon.url} />
                        </SocialLink>
                      )
                    })}
                  </Icons>
                ) : null}
                <Bottom>{state.content.footer && state.content.footer.copyright}</Bottom>
              </Socials>
              {state.content.footer.column && state.content.footer.column.length
                ? state.content.footer.column?.map((item: any, index: number) => {
                    return (
                      <SubContainer key={`footer-columns-first-${index}`}>
                        {item.title ? <TitleCol>{item.title}</TitleCol> : null}
                        <Column>
                          {item.link.map((link: any, position: number) => {
                            return (
                              <LinkExternal key={`footer-columns-second-${position}`} href={link.path}>
                                <Item>{link.label}</Item>
                              </LinkExternal>
                            )
                          })}
                        </Column>
                      </SubContainer>
                    )
                  })
                : null}
            </Options>
          )
        ) : (
          <>
            <SocialContainer>
              <SocialIcons>
                {state.content.footer.social && state.content.footer.social.length
                  ? state.content.footer.social?.map((item: any, index: number) => {
                      return (
                        <SocialLink key={`social-icons-footer-${index}`} href={item.url} target="blank">
                          <SocialIcon src={item.icon.url} />
                        </SocialLink>
                      )
                    })
                  : null}
              </SocialIcons>
            </SocialContainer>
            <ColumnsContainer>
              {state.content.footer.column && state.content.footer.column.length
                ? state.content.footer.column?.map((item: any, index: number) => {
                    return (
                      <SubContainerResponsive key={`footer-columns-responsive-${index}`}>
                        {item.title ? <TitleCol>{item.title}</TitleCol> : null}
                        <Column>
                          {item.link.map((link: any, position: number) => {
                            return (
                              <LinkExternal key={`footer-sub-map-responsive-${position}`} href={link.path}>
                                <Item>{link.label}</Item>
                              </LinkExternal>
                            )
                          })}
                        </Column>
                      </SubContainerResponsive>
                    )
                  })
                : null}
            </ColumnsContainer>
            <CopyrightContainer>
              {state.content.footer.copyright ? <Copyright>{state.content.footer.copyright}</Copyright> : null}
            </CopyrightContainer>
          </>
        )}
      </Content>
    </Container>
  )
}

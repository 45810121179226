import styled from 'styled-components'

export const Container = styled.div`
  height: 686px;
  width: 100%;
  background-color: #fffbf4;

  @media screen and (max-width: 820px) {
    height: auto;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 820px) {
    justify-content: center;
  }
`

export const Card = styled.div`
  // z-index: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 0 32px;
  width: 45%;
  max-width: 700px;
  height: auto;
  margin-top: 8%;
  margin-left: 4%;
  background-color: #fefcf7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 30px;

  @media screen and (max-width: 1320px) {
    width: 60%;
  }

  @media screen and (max-width: 1070px) {
    width: 70%;
  }

  @media screen and (max-width: 880px) {
    width: 80%;
  }

  @media screen and (max-width: 820px) {
    width: 90%;
    margin: auto;
    padding-right: 10px;
  }
`

export const Image = styled.img`
  width: 1015px;
  height: 100%;
  float: right;
  object-fit: cover;

  @media screen and (max-width: 623px) {
    display: flex;
  }

  @media screen and (max-width: 650px) {
    // width: 100%;
    margin-top: 285px;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 500px) {
    margin-top: 320px;
  }

  @media screen and (max-width: 440px) {
    margin-top: 360px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;

  // margin-left: 38%;
`

export const Button = styled.a`
  display: block;
  color: #062a20;
  background-color: #ffc42d;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-left: 30px;

  ::after {
    content: 'ᐳ';
    margin-left: 20px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const Title = styled.h1`
  font-family: Montserrat;
  color: #010101;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 42px;
  text-align: left;

  @media screen and (max-width: 820px) {
    font-size: 28px;
    line-height: 26px;
  }
`

export const CardContent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29.89px;
  text-align: left;
  letter-spacing: 0.0930704px;
  color: #062a20;

  @media screen and (max-width: 820px) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const ContentContainer = styled.div`
  // padding-left: 30px;
  margin-top: -40px;
  padding: 30px;
`

export const CardHeader = styled.header`
  padding-top: 5px;

  padding-left: 30px;
`

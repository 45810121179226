import React, { FC, useEffect, useState } from 'react'
import { Container, DateInput } from './styled'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './picker.css'
import { useDispatch } from 'react-redux'
import { setDate } from 'ducks/metrics'

export const RangeDatePicker: FC = () => {
  const [dateRange, setDateRange] = useState<any>([
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    new Date(),
  ])
  const [startDate, endDate] = dateRange

  const dispatch = useDispatch()

  const currentDate = new Date()

  const subtractDays = () => {
    let previousDate
    if (startDate) {
      previousDate = new Date(new Date().setDate(currentDate.getDate() - 5))
    }
    return previousDate
  }

  useEffect(() => {
    if ((startDate && startDate.toDateString() === currentDate.toDateString()) || startDate > currentDate) {
      dispatch(
        setDate({ start: subtractDays()?.toISOString().split('T')[0], end: currentDate?.toISOString().split('T')[0] })
      )
    } else if (endDate > currentDate) {
      dispatch(
        setDate({ start: startDate?.toISOString().split('T')[0], end: currentDate?.toISOString().split('T')[0] })
      )
    } else {
      dispatch(setDate({ start: startDate?.toISOString().split('T')[0], end: endDate?.toISOString().split('T')[0] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate])

  const element: any = document.querySelector(`input`)
  if (element) {
    element.setAttribute('custom-input', 'on')
  }

  const element2: any = document.querySelector(`button`)
  if (element2) {
    element2.setAttribute('custom-button', 'on')
  }

  const filterDays = (date: any) => {
    return date < currentDate
  }

  return (
    <Container>
      <DatePicker
        id="date-picker"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: any) => {
          setDateRange(update)
        }}
        placeholderText="Select date"
        isClearable={true}
        filterDate={filterDays}
      />
    </Container>
  )
}

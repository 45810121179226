import { combineReducers } from '@reduxjs/toolkit'
import { metricsReducer } from 'ducks/metrics'
import { staticReducer } from 'ducks/static'
import { userReducer } from 'ducks/user/reducer'

export const rootReducer = combineReducers({
  user: userReducer,
  staticContent: staticReducer,
  metrics: metricsReducer,
})

import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 91.5%;
  color: #062a20;
  text-align: justify;
  margin-bottom: 30px;
  margin-left: 10px;
`

export const Card = styled.div`
  height: 276px;
  background: #fefcf7;
  box-shadow: 0px 5.01818px 18.8182px 2.50909px rgba(160, 156, 145, 0.17);
  border-radius: 50.1818px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LeftIconContainer = styled.div`
  height: 92px;
  width: 92px;
  background-color: #dfa000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`

export const Icon = styled.img`
  height: 35px;
`

export const TextContainer = styled.div`
  // width: 60%;
`

export const Info = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.106607px;
`

export const InfoOr = styled(Info)`
  color: #dfa000;
`

export const InfoGreen = styled(Info)`
  color: #54ae2a;
`

export const Vector = styled.img`
  height: 27px;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RightIconContainer = styled.div`
  height: 92px;
  width: 92px;
  background-color: #54ae2a;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`

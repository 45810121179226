import styled from 'styled-components'

export const Container = styled.div`
  height: auto;
  // width: 100%;
  background-color: #fffbf4;
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export const VideoContainer = styled.div`
  align-self: center;
  width: 90%;
  margin-top: 2rem;
`

export const VideoWrapper = styled.div`
  width: 100% !important;
  height: 618px !important;
  @media screen and (max-width: 1059px) {
    height: 500px !important;
  }
  @media screen and (max-width: 768px) {
    height: 400px !important;
  }
  @media screen and (max-width: 650px) {
    height: 300px !important;
  }
  @media screen and (max-width: 450px) {
    height: 200px !important;
  }
`

export const InfoContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const Info = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.07px;
  color: #717171;
`

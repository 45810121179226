import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  width: 85%;
  // height: 400px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`

export const TitleContainer = styled.div`
  // width: 50%;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 91.5%;
  color: #062a20;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  height: 250px;
`

export const CallActionContainer = styled.div`
  // width: 80%;
  margin-top: 30px;
  // margin-bottom: 20px;
`

export const CallAction = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 111%;
  color: #062a20;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.a`
  display: block;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 52px;

  ::after {
    content: 'ᐳ';
    margin-left: 20px;
  }

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
`

export const PhotoSection = styled.div`
  max-width: 750px;
  // min-height: 196px;

  & > .carousel-root {
    display: flex;
    &:hover {
      border-radius: 50px;
    }
  }
  & .slider-wrapper {
    height: 100%;
  }
  & .slider-wrapper > .slider {
    height: 100%;
  }
  & .control-next {
    border-radius: 0px 50px 50px 0px;
  }
  & .control-prev {
    border-radius: 50px 0px 0px 50px;
  }
`

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50px;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  width: 70%;
`

export const Card = styled.div`
  width: 750px;
  height: 230px;
  background: #dfa000;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

export const Card2 = styled(Card)`
  background: #764c29;
`

export const Card3 = styled(Card)`
  background: #0b9444;
`

export const Card4 = styled(Card)`
  background: #3c6685;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  width: 70%;
`

export const TextContainer = styled.div`
  width: 50%;
  margin-bottom: 2rem;
`

export const RightText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 109%;
  color: #062a20;
`

export const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`

export const Numbers = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 91.5%;
  color: #fbf7ef;
  margin-bottom: 8px;
`

export const Numbers3 = styled(Numbers)`
  // margin-right: 60px;
`

export const LeftTextCard = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 98%;
  text-align: center;
  color: #fbf7ef;
  width: 80%;
  margin: auto;
`

export const LeftTextCard2 = styled(LeftTextCard)`
  width: 85%;
`

export const LeftTextCard4 = styled(LeftTextCard)`
  width: 70%;
`

export const MiddleIcon = styled.img`
  max-width: 15px;
  height: 10px;
`

export const MiddleIcon1 = styled(MiddleIcon)`
  // margin-left: 15px;
`

export const MiddleIcon3 = styled(MiddleIcon)`
  // margin-left: 25px;
`

export const MiddleIcon4 = styled(MiddleIcon)`
  // margin-left: 40px;
`

export const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`

export const RightTextCard = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 98%;
  text-align: center;
  color: #fbf7ef;
  // width: 90%;
  margin: auto;
`

export const RightTextCard2 = styled(RightTextCard)`
  // width: 85%;
`

export const RightTextCard3 = styled(RightTextCard)`
  // width: 70%;
`

export const RightTextCard4 = styled(RightTextCard)`
  // width: 65%;
`

export const RightIcon = styled.img`
  max-width: 100px;
  height: 100px;
  margin-right: 10px;
`

export const RightIcon2 = styled(RightIcon)`
  max-width: 100px;
  height: 70px;
  margin-right: 20px;
`

export const RightIcon3 = styled(RightIcon)`
  max-width: 108px;
  height: 83px;
  margin-right: 20px;
`

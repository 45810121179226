import styled, { css } from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.div`
  /*   display: flex;
  flex-direction: row; */
  background-color: #fffbf4;
  width: 100%;
  height: 585px;

  @media screen and (max-width: 1330px) {
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    height: 1000px;
  }

  @media screen and (max-width: 865px) {
    height: 950px;
  }

  @media screen and (max-width: 500px) {
    height: 670px;
  }

  @media screen and (max-width: 384px) {
    height: 690px;
  }

  @media screen and (max-width: 344px) {
    height: 730px;
  }
`

export const Content = styled.div`
  max-width: 1370px;
  margin: auto;
  /*   display: flex;
  flex-direction: row; */
  // align-items: center;

  @media screen and (max-width: 1330px) {
    display: flex;
    flex-direction: column;
  }
`

export const Card = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 0 32px;
  width: 45%;
  // max-width: 700px;
  height: auto;
  margin-top: 6%;
  margin-left: 4%;
  background-color: #fefcf7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 30px;

  @media screen and (max-width: 1330px) {
    width: 60%;
    // margin-top: 45%;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin-top: 57%;
  }
`

export const Title = styled.h1`
  font-family: Montserrat;
  color: #062a20;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 49px;
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const CardContent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29.89px;
  text-align: left;
  letter-spacing: 0.0930704px;
  color: #062a20;

  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0562949px;
  }
`

export const ContentContainer = styled.div`
  // padding-left: 30px;
  margin-top: -40px;
  padding: 30px;
`

export const CardHeader = styled.header`
  padding-top: 5px;
  padding-left: 30px;
`

export const Image = styled.img`
  width: 937px;
  height: 100%;
  float: right;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const ImageContainer = styled.div`
  width: 100%;

  // margin-left: 42%;
`

export const Button = styled.a`
  display: block;
  color: #ffffff;
  background-color: #062a20;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 42px;
  justify-content: center;
  margin-left: 30px;

  &:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
    transform: translate(0, -1px);
  }
  ::after {
    content: 'ᐳ';
    margin-left: 40px;
  }
`

export const link = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px 16px;
  &:hover {
    color: #dfa000;
    text-decoration: underline;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const ExternalLink = styled.a`
  ${link}
`

export const ContentImage = styled.div<BackgroundProps>`
  ${background};
  // background-position-x: center;
  // background-position-y: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // width: 50%;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 300px;
  }
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
`
